import React, { useEffect, useState } from "react";
import save from "../../../images/courses/course-details/save.svg";
import reHeart from "../../../images/courses/course-details/red-heart.svg";
import download from "../../../images/courses/course-details/download.svg";
import rightarrow from "../../../images/courses/course-details/rightarrow.svg";
import type { SVGProps } from "react";

export function AkarIconsQuestion(props: SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="1.5em"
      height="1.5em"
      viewBox="0 0 24 24"
      {...props}
    >
      <g fill="none" stroke="#00CC99" strokeWidth={2}>
        <circle cx={12} cy={12} r={10}></circle>
        <path
          strokeLinecap="round"
          d="M10 8.484C10.5 7.494 11 7 12 7c1.246 0 2 .989 2 1.978s-.5 1.483-2 2.473V13m0 3.5v.5"
        ></path>
      </g>
    </svg>
  );
}
interface ExamBannerData {
  examBanner: string;
  examTitle: string;
  examDescription: string;
}

const Banner: React.FC = () => {
  const [bgImage, setBgImage] = useState<string>("");
  const [bannerData, setBannerData] = useState<ExamBannerData | null>(null);
  const [smalldevice, setSmalldevice] = useState(false);
  const [isRed, setIsRed] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleClick = () => {
    setIsRed((prevIsRed) => !prevIsRed);
  };

  useEffect(() => {
    // Fetch banner data from API
    const fetchBannerData = async () => {
      try {
        const response = await fetch(
          "https://mentormenteedev.com/api/exam-details/NEET"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch banner data");
        }
        const data = await response.json();
        setBannerData(data);
        setBgImage(data.examBanner); // Set background image
      } catch (error) {
        console.error("Error fetching banner data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchBannerData();
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 768) {
        setSmalldevice(true);
      } else {
        setSmalldevice(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  if (loading) {
    return (
      <div className="flex justify-center items-center h-64 bg-gray-200">
        <p>Loading...</p>
      </div>
    );
  }

  if (!bannerData) {
    return null;
  }

  return (
    <div
      style={{
        backgroundSize: "cover",
        backgroundImage: `url(${bgImage})`,
      }}
      className="relative bg-blue-900"
    >
      <div className="relative container mx-auto py-8 lg:py-28">
        <div className="w-full lg:w-[650px]">
          <div className="flex flex-col max-lg:gap-12">
            <div className="w-full flex flex-row gap-4 items-start max-md:mt-2">
              <div className="flex flex-col gap-10">
                <div className="flex flex-col gap-4">
                  <div className="flex">
                    <h3 className="text-2xl lg:text-[40px] font-inter font-bold lg:leading-[60px] text-white m-0">
                      {bannerData.examTitle}
                    </h3>
                  </div>
                  <p className="text-inter text-lg lg:text-xl leading-9 text-white">
                    {bannerData.examDescription}
                  </p>
                </div>
                <div className="flex flex-row gap-2 lg:gap-10 items-center">
                  <button
                    className="flex flex-row text-white text-sm lg:text-base font-medium leading-8 rounded-lg whitespace-nowrap items-center"
                    onClick={handleClick}
                  >
                    {!isRed ? (
                      <img src={save} className="pr-2" alt="Save Icon" />
                    ) : (
                      <img src={reHeart} className="pr-2" alt="Saved Icon" />
                    )}
                    Save
                  </button>
                  <button className="flex gap-2 flex-row text-white text-sm lg:text-base font-medium leading-8 rounded-lg items-center">
                    <AkarIconsQuestion />
                    Ask Question
                  </button>
                  <a
                    href="/sign-in"
                    className="flex flex-row text-white text-sm lg:text-base font-medium leading-8 rounded-lg items-center"
                  >
                    <img src={download} className="pr-2" />
                    Download Guide
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-9 flex lg:mt-24 gap-10 p-1 sm:p-2 bg-[#00CC99] rounded-full max-xs:rounded-xl">
            <div className="flex relative w-full gap-2.5 lg:gap-8">
              <a
                href="/apply"
                className="flex flex-row w-full font-inter text-white max-xss:text-[9px] text-[10px] sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-1 sm:p-2"
              >
                <span>Apply Now</span>
                <img
                  src={rightarrow}
                  className="w-5 h-5 max-xss:w-4 max-xss:h-4 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300"
                  alt="Arrow Icon"
                />
              </a>
              <a
                href="/mentor"
                className="flex flex-row w-full font-inter text-white max-xss:text-[9px] text-[10px] sm:text-sm md:text-base font-medium md:leading-8 rounded-3xl bg-[#173CBA] group cursor-pointer justify-between items-center p-1 sm:p-2"
              >
                <span>Talk to Mentor</span>
                <img
                  src={rightarrow}
                  className="w-5 h-5 max-xss:w-4 max-xss:h-4 lg:w-8 lg:h-8 group-hover:translate-x-2 transition-transform duration-300"
                  alt="Arrow Icon"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Banner;
