import { FC, useState } from "react";
import logo from "../../../images/cm-main-logo.svg";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBars,
  faChevronLeft,
  faChevronRight,
  faX,
} from "@fortawesome/free-solid-svg-icons";
import CmLogo from "../../../images/cm-main-logo.svg";
import UserAvatar from "../../../components/UserAvatar";


interface IProps {
  categories: any;
  sectionsList: any;
}

const BlogsMobileHeader: FC<IProps> = ({ sectionsList, categories }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedTopic, setSelectedTopic] = useState<string | null>(null);
  let currentUser = localStorage.getItem("user");
  const navigate = useNavigate();

  const handleSubTopicClick = (subTopic: string) => {
    const slug = sectionsList.find(
      (section: any) => section?.title == selectedTopic
    )?.slug;
    setSelectedTopic(null);
    setIsMenuOpen(false);
    navigate(`/blogs/${slug}`, { state: { subTopic: subTopic } });
  };

  return (
    <>
      <div className="relative z-10 flex items-center justify-between px-8 py-4">
        <Link to="/" className="main-logo">
          <img src={logo} alt="logo" />
        </Link>
        <div className="">
        {!currentUser ? (
                <button
                  className="py-2.5 px-2 md:px-6 blue-color button-styles text-sm font-semibold rounded-full duration-200"
                  onClick={(e) => navigate("/secure/login")}
                >
                  login
                </button>
              ) : (
                ""
              )}

            {currentUser && (
              <UserAvatar
                link={"dashboard/my-profile"}
                logoutUrl={"secure/login"}
              />
            )}
            </div>
        <button onClick={() => setIsMenuOpen(true)}>
          <FontAwesomeIcon icon={faBars} />
        </button>
      </div>
      {isMenuOpen && (
        <div
          className="fixed inset-0 z-40 bg-black opacity-80"
          onClick={() => setIsMenuOpen(false)}
        ></div>
      )}
      <div
        className="fixed h-screen w-[80vw] top-0 left-0 flex flex-col bg-white transition-transform duration-500 z-50"
        style={{ transform: isMenuOpen ? "" : "translateX(-100%)" }}
      >
        {/* cm logo */}
        {!selectedTopic && (<>
          <div className="px-4 py-12 bg-blue">
            <Link to="/blogs">
              <img
                src={CmLogo}
                alt="college mentor logo"
                className="w-48 p-2 bg-white rounded-lg"
              />
            </Link>
          </div>
            
            </>
        )}
        {/* sub topic list */}
        {selectedTopic ? (
          <>
          <div>
            <div className="p-4 flex items-center gap-4 py-4 text-white bg-blue">
              <button onClick={() => setSelectedTopic(null)}>
                <FontAwesomeIcon icon={faChevronLeft} className="text-white" />
              </button>
              <span>{selectedTopic}</span>
            </div>
            <div className="px-4 flex flex-col divide-y-2 divide-grey-3">
              {Object.keys(categories?.[selectedTopic]?.subcategories).map(
                (subTopic) => (
                  <li
                    className="list-none py-4"
                    onClick={() => handleSubTopicClick(subTopic)}
                  >
                    {subTopic}
                  </li>
                )
              )}
            </div>
          </div>
         
          </>
        ) : (
          // main topic list
          <>
          <ul className="block px-4 flex flex-col divide-y-2 divide-grey-3">
            {sectionsList.map((section: any) => (
              <li
                key={section.slug}
                className="list-none py-4 flex items-center justify-between"
              >
                <Link
                  to={`/blogs/${section.slug}`}
                  onClick={() => setIsMenuOpen(false)}
                >
                  {section.title}
                </Link>
                <button onClick={() => setSelectedTopic(section.title)}>
                  <FontAwesomeIcon
                    icon={faChevronRight}
                    className="text-blue"
                  />
                </button>
              </li>
            ))}
          </ul>
         
          </>
        )}
             <div className="TAndC flex flex-col w-full gap-4 mt-8 items-center mt-auto mb-4">
        <div className="bg-white opacity-80">
          <div className="w-24 h-0.5 bg-blue mx-auto max-lg:mx-0"></div>
        </div>
        <div className="container flex flex-col justify-between items-center z-10">
        <div className="flex flex-col justify-between">
          <div className="flex justify-center ">
  <Link to="/blogs/disclaimer" className="text-black px-2 rounded border-r border-white whitespace-nowrap">
    <span className="font-bold">Disclaimer</span>
  </Link>
  <Link to="/blogs/policy" className="text-black px-2 rounded border-r border-white whitespace-nowrap">
    <span className="font-bold">Privacy Policy</span>
  </Link></div>
  <Link to="/blogs/terms" className="flex justify-center text-black px-2 rounded whitespace-nowrap">
    <span className="font-bold">Terms & Conditions</span>
  </Link>
</div>




  {/* <div className="text-center px-4 whitespace-nowrap">
    Copyright &copy; {new Date().getFullYear()} <br/>Dreams and Degrees Pvt Ltd
  </div>

  <div className="flex justify-end whitespace-nowrap">
    <span>
      Partner Site: <span className="font-bold">College Mentor</span>
    </span>
  </div> */}

  
</div>


        
      </div>
        <button
          className="absolute top-4 right-4"
          onClick={() => setIsMenuOpen(false)}
        >
          <FontAwesomeIcon icon={faX} className="text-white" />
        </button>
      </div>
    
    </>
  );
};

export default BlogsMobileHeader;
