import React from "react";
import planeSvg from "../../../../../../images/authentication/plane-svg.svg";
import planeWhiteSvg from "../../../../../../images/authentication/plane-white-svg.svg";
import PlaneWhite from "../../../../../../images/authentication/aircraft-white.svg";
import GirlSvg from "../../../../../../images/authentication/registeration_icon.png";
import NewPlane from "../../../../../../images/authentication/plane-new.svg";

interface CurvedProgressBarProps {
  currentStep: number;
  steps: number;
}
const CurvedProgressBar: React.FC<CurvedProgressBarProps> = ({
  steps,
  currentStep,
}) => {
  const width = 300; // Width of the SVG
  const height = 150; // Height of the SVG
  const stepWidth = width / (steps - 1); // Width of each step
  const radius = height / 4; // Radius for the inverted U

  // Function to calculate the y position for the circles and the curve based on x
  const calculateY = (x: any) => {
    const controlX = width / 2;
    const controlY = height - radius; // Control point for the curve
    const a = radius / (controlX * controlX); // Adjust 'a' to ensure proper curve height
    return height - (-(a * Math.pow(x - controlX, 2)) + controlY);
  };

  // Function to generate the full curve connecting circles
  const drawFullCurve = () => {
    let path = `M 0 ${height - 80}`; // Start from the left bottom
    for (let i = 0; i < steps; i++) {
      const x = i * stepWidth; // Calculate x position for each step
      const y = calculateY(x); // Calculate y position based on the curve
      path += ` L ${x} ${y}`; // Connect each point with a line
    }
    return path;
  };

  return (
    <>
      <div style={{ position: "relative" }}>
        <svg width={width} height={height}>
          {/* Draw the full inverted U curve */}
          <path
            d={drawFullCurve()}
            stroke="white"
            fill="transparent"
            // strokeWidth="4"
            stroke-dasharray="5,5"
          />

          {/* Draw the progress line */}
          {/* <path
            d={drawFullCurve()
              .split("L")
              .slice(0, currentStep + 1)
              .join("L")}
            stroke="white"
            fill="transparent"
            strokeWidth="3" // Slightly smaller width to make it appear above the light gray line
            style={{
              // transition: 'stroke-dasharray 0.5s ease', // Smooth transition
              transition: "stroke-dasharray 1s ease 0.3s", // Smooth transition with delay
            }}
          /> */}

          {/* Draw steps */}
          {Array.from({ length: steps }, (_, index) => {
            const x = index * stepWidth; // X position for each step
            const y = calculateY(x); // Y position based on the curve
            return (
              <circle
                key={index}
                cx={x} // Circle's X position
                cy={y} // Circle's Y position based on the curve
                r="2"
                // fill={"transparent"}
                fill={index < currentStep ? "transparent" : "white"}
                style={{
                  // transition: 'fill 0.5s ease', // Smooth transition for circle fill
                  transition: "fill 1s ease 0.3s", // Smooth transition for circle fill with delay
                }}
              />
            );
          })}
        </svg>

        {/* Render the SVG icon exactly at the current step, aligned with the blue circle */}
        {currentStep > 0 && (
          <img
            src={NewPlane} // Use the imported SVG
            alt="Step Icon"
            style={{
              position: "absolute",
              left: (currentStep - 1) * stepWidth - 12, // Center the icon on the previous step
              top: calculateY((currentStep - 1) * stepWidth) - 12, // Position the icon aligned with the previous step
              width: "34px", // Set the size of the icon to match the circle
              height: "34px",
              // transition: 'left 0.5s ease, top 0.5s ease', // Smooth movement transition
              transition: "left 1s ease 0.3s, top 1s ease 0.3s", // Smooth movement transition with delay
            }}
          />
        )}
      </div>
    </>
  );
};

export default CurvedProgressBar;
