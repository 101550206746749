import { useState } from "react";
import Explore from "./Explore";
import Shortlist from "./Shortlist";
import Application from "./Application";
const tabs = [
  {
    name: "Explore",
    element: <Explore />,
  },
  {
    name: "Shortlisted",
    element: <Shortlist />,
  },
  {
    name: "Applications",
    element: <Application />,
  },
];

const Admissions = () => {
  const [activeTab, setActiveTab] = useState(0);

  return (
    <div className="">
      <nav className="flex gap-4 p-4">
        {tabs.map((tab, index) => (
          <button
            className={`px-4 py-2 ${
              activeTab == index ? "text-white bg-green" : ""
            }`}
            onClick={() => setActiveTab(index)}
          >
            {tab.name}
          </button>
        ))}
      </nav>
      <div className="mt-8">{tabs[activeTab].element}</div>
    </div>
  );
};

export default Admissions;
