import React, { useState, useEffect, useRef } from "react";
import type { SVGProps } from "react";

// SVG Component for Arrow
export function LineMdArrowSmallRight(props: SVGProps<SVGSVGElement>) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" {...props}>
      <g transform="translate(24 0) scale(-1 1)">
        <g
          fill="none"
          stroke="currentColor"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth={2}
        >
          <path strokeDasharray={14} strokeDashoffset={14} d="M19 12H5.5">
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              dur="0.3s"
              values="14;0"
            ></animate>
          </path>
          <path
            strokeDasharray={8}
            strokeDashoffset={8}
            d="M5 12L10 17M5 12L10 7"
          >
            <animate
              fill="freeze"
              attributeName="stroke-dashoffset"
              begin="0.3s"
              dur="0.2s"
              values="8;0"
            ></animate>
          </path>
        </g>
      </g>
    </svg>
  );
}

interface Tab {
  id: number;
  title: string;
  section: string;
}

const TabNavigations: React.FC = () => {
  const tabs: Tab[] = [
    { id: 1, title: "How to Become", section: "how-become" },
    { id: 2, title: "History", section: "timeline" },
    { id: 3, title: "Pathway", section: "pathway" },
    { id: 4, title: "Certifications", section: "certifications" },
    { id: 5, title: "Responsibilities", section: "responsibilities" },
    { id: 6, title: "Knowledge & Skills", section: "knowledge-skills" },
    { id: 7, title: "Salary", section: "salary" },
    { id: 8, title: "Roadmap", section: "roadmap" },
    { id: 9, title: "Career Paths", section: "career-paths" },
    { id: 10, title: "Entrance Exams", section: "entrance-exams" },
    {
      id: 11,
      title: "Data Science",
      section: "colleges-data-science",
    },
    { id: 12, title: "Talk to Professional", section: "talk-to-professional" },
    { id: 13, title: "FAQs", section: "faqs" },
    { id: 14, title: "Career Types", section: "career-types" },
    { id: 15, title: "Resources", section: "resources" },
  ];

  // Set the initial active tab to the first tab by default
  const [activeTab, setActiveTab] = useState<number>(1);
  const [isSticky, setIsSticky] = useState(false);
  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);
  const menuRef = useRef<HTMLUListElement>(null);
  const isScrollingRef = useRef<boolean>(false);

  const handleClick = (id: number) => (event: React.MouseEvent) => {
    event.preventDefault();
    const tab = tabs.find((t) => t.id === id);
    if (tab) {
      isScrollingRef.current = true;
      setActiveTab(id); // Set the clicked tab as active
      scrollToSection(`#${tab.section}`);
      scrollToActiveTab();
      setTimeout(() => (isScrollingRef.current = false), 500);
    }
  };

  const scrollLeft = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (menuRef.current) {
      menuRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handleScroll = () => {
    if (menuRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = menuRef.current;
      setCanScrollLeft(scrollLeft > 0);
      setCanScrollRight(scrollLeft < scrollWidth - clientWidth - 1);
    }
  };

  const scrollToActiveTab = () => {
    const activeIndex = tabs.findIndex((t) => t.id === activeTab);
    const tabElement = menuRef.current?.children[activeIndex] as HTMLLIElement;
    if (tabElement && menuRef.current) {
      const tabLeft = tabElement.offsetLeft;
      const tabRight = tabLeft + tabElement.offsetWidth;
      const containerScrollLeft = menuRef.current.scrollLeft;
      const containerWidth = menuRef.current.clientWidth;

      if (tabLeft < containerScrollLeft) {
        menuRef.current.scrollTo({ left: tabLeft, behavior: "smooth" });
      } else if (tabRight > containerScrollLeft + containerWidth) {
        menuRef.current.scrollTo({
          left: tabRight - containerWidth,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollToSection = (sectionId: string) => {
    const section = document.querySelector(sectionId) as HTMLElement;
    if (section) {
      const offset = 70;
      const y =
        section.getBoundingClientRect().top + window.pageYOffset - offset;
      window.scrollTo({ top: y, behavior: "smooth" });
    }
  };

  const updateActiveTabOnScroll = () => {
    if (isScrollingRef.current) return;

    let found = false;
    tabs.forEach((tab) => {
      const section = document.getElementById(tab.section);
      if (section) {
        const rect = section.getBoundingClientRect();
        const isVisible = rect.top <= 100 && rect.bottom >= 70;
        if (isVisible && !found) {
          setActiveTab(tab.id);
          scrollToActiveTab();
          found = true;
        }
      }
    });
  };

  useEffect(() => {
    const handleScrollEvent = () =>
      requestAnimationFrame(updateActiveTabOnScroll);

    const checkStickyPosition = () => {
      const stickyThreshold = 700;
      setIsSticky(window.pageYOffset > stickyThreshold);
    };

    window.addEventListener("scroll", handleScrollEvent);
    window.addEventListener("scroll", checkStickyPosition);

    return () => {
      window.removeEventListener("scroll", handleScrollEvent);
      window.removeEventListener("scroll", checkStickyPosition);
    };
  }, [tabs]);

  return (
    <div className="flex flex-col justify-center relative">
      <div
        className={`w-full mx-auto max-md:px-3 py-4 ${
          isSticky
            ? "fixed top-0 z-20 transition duration-300 shadow-lg"
            : "relative transition duration-300"
        }`}
        style={{
          background: "linear-gradient(180deg, #0a2788 0%, #06AFA9 100%)",
        }}
      >
        <div className="container mx-auto flex items-center relative max-md:px-0">
          <div className="w-full relative transition duration-300 flex items-center mx-auto">
            <button
              onClick={scrollLeft}
              className={`absolute bg-[#00CC99] left-0 p-[2px] md:p-1 border border-white rounded text-white cursor-pointer ${
                !canScrollLeft
                  ? "opacity-50 cursor-not-allowed"
                  : "opacity-50 hover:opacity-100"
              }`}
              disabled={!canScrollLeft}
            >
              <LineMdArrowSmallRight className="rotate-180 w-4 h-4 md:w-6 md:h-6" />
            </button>
            <div className="flex items-center overflow-hidden px-6 md:px-12">
              <ul
                className="tabs flex gap-1.5 sm:gap-4 overflow-x-auto whitespace-nowrap scrollbar-hide list-none"
                ref={menuRef}
                onScroll={handleScroll}
              >
                {tabs.map((tab) => (
                  <li key={tab.id}>
                    <a
                      href={`#${tab.section}`}
                      className={`rounded text-xs sm:text-base text-black py-1.5 block px-3.5 font-semibold ${
                        activeTab === tab.id
                          ? "active bg-[#14E0AD] duration-75"
                          : "bg-white duration-75"
                      } cursor-pointer`}
                      onClick={(event) => handleClick(tab.id)(event)}
                    >
                      {tab.title}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
            <button
              onClick={scrollRight}
              className={`absolute right-0 p-[2px] md:p-1 bg-[#00CC99] border border-white rounded text-white cursor-pointer ${
                !canScrollRight ? "opacity-50 cursor-not-allowed" : ""
              }`}
              disabled={!canScrollRight}
            >
              <LineMdArrowSmallRight className="w-4 h-4 md:w-6 md:h-6" />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TabNavigations;
