import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LeadGeneration from "../../../components/LeadGeneration";

const images = [
  "/images/explore-clg-life01.jpg",
  "/images/explore-clg-life02.png",
  "/images/explore-clg-life03.jpg",
];

const ExploreCollegeLife: React.FC = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isPopupVisible, setIsPopupVisible] = useState<boolean>(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleDotClick = (index: number) => {
    setCurrentIndex(index);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 2000);

    return () => clearInterval(interval);
  }, []);

  return (
    <section className="flex flex-col rounded-none mb-7">
      <div className="px-20 py-11 w-full bg-[#FFF6F1] max-md:px-5 max-md:max-w-full">
        <div className="flex gap-24 max-md:flex-col">
          <div className="flex flex-col w-2/5 max-md:w-full">
            <div className="flex flex-col w-full max-md:mt-10">
              <h1 className="text-4xl font-bold text-[#173CBA]">
                Explore College Life <br /> For{" "}
                <span className="text-[#0C9]">Students & Parents</span>
              </h1>
              <p className="mt-3 text-base text-black leading-7 max-md:mr-2.5">
                India's first-of-its-kind edtech platform offering both
                off-campus and online virtual campus tours would be a pioneering
                development in higher education. Making it easier for students
                and parents to explore educational institutions comprehensively.
                We Get You Through the Door: Unlock Campus Tours and Admissions
                Insights
              </p>
              <div className="flex gap-5 mt-5 max-w-full ">
                <Link
                  to={"/applications-admissions-explore-college"}
                  className="flex gap-0.5 justify-center items-center text-white button-styles  arrow-btn hover:bg-[#0c9] rounded "
                >
                  <span className="self-stretch text-sm font-semibold">
                    Know More
                  </span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="currentColor"
                    className="ml-2 arrow-icon"
                  >
                    <path
                      d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                      fill="currentColor"
                    />
                  </svg>
                </Link>
                <button
                  onClick={handlePopup}
                  className="flex gap-0.5 justify-center items-center button-styles arrow-button arrow-btn hover:bg-[#0c9] text-white rounded "
                >
                  <span className="self-stretch  text-sm font-semibold ">
                    Book your Campus Tour
                  </span>

                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="15"
                    height="12"
                    viewBox="0 0 15 12"
                    fill="currentColor"
                    className="arrow-icon"
                  >
                    <path
                      d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                      fill="currentColor"
                    />
                  </svg>
                </button>
              </div>
            </div>
          </div>
          <div className="relative flex-1 ">
            <div className="relative flex flex-col rounded-none max-w-[610px] overflow-hidden max-md:w-[395px]">
              <div className="relative w-full max-w-[527px] max-md:max-w-full">
                <div className="flex max-md:flex-col">
                  <div className="flex flex-col md:w-[388px] max-md:w-[250px] max-md:ml-0 ">
                    <img
                      loading="lazy"
                      src="/images/explore-clg-life01.jpg"
                      alt=""
                      className="object-contain grow w-full rounded-lg aspect-[1.17]"
                    />
                  </div>
                  <div className="flex flex-col md:ml-[-60px] md:w-[200px] max-md:ml-0  z-10">
                    <div className="md:mt-48 text-3xl font-semibold text-center text-white max-md:mt-[-80px]">
                      <span className="bg-blue rounded-md p-1">
                        We get you in
                      </span>
                      <br />
                      <span className="rounded-md bg-[#0c9] p-1">
                        the door.
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="relative md:mt-[-95px] md:ml-[185px] max-md:ml-[80px] max-md:mt-[-40px]">
                <img
                  loading="lazy"
                  src={images[(currentIndex + 1) % images.length]}
                  alt="Secondary visual content"
                  className="object-contain w-full max-md:w-[250px] rounded-xl aspect-[1.75] "
                />
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="57"
                  height="7"
                  viewBox="0 0 57 7"
                  fill="none"
                  className="mx-auto mt-3"
                >
                  {images.map((_, index) => (
                    <circle
                      key={index}
                      cx={index * 18 + 3.5}
                      cy="3.5"
                      r="3.5"
                      fill={index === currentIndex ? "#00CC99" : "#173CBA"}
                      className="cursor-pointer"
                      onClick={() => handleDotClick(index)}
                    />
                  ))}
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ExploreCollegeLife;
