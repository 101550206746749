import React, { useState, useEffect } from "react";
import axios from "axios";
import Banner from "../../components/study-abroad-landing/Banner/Banner";
import Loader from "../../components/Loader/Loader";
import CarosalLogos from "../../components/study-abroad-landing/CarosalLogos";
import AboutEducationMentorsGlobally from "../../components/study-abroad-landing/AboutEducationMentorsGlobally";
import WhyStudyAbroadSec from "../../components/study-abroad-landing/WhyStudyAbroad";
import ChooseYourDreamCountry from "../../components/study-abroad-landing/ChooseYourDreamCountry";
import SuccessStories from "../../components/study-abroad-landing/SuccessStories";
import UniversitiesWorldwideMentors from "../../components/study-abroad-landing/UniversitiesWorldwideMentors";
import TopRankedUniversitiesQS from "../../components/study-abroad-landing/TopRankedUniversitiesQS";
import KnowledgeHub from "../../components/study-abroad-landing/KnowledgeHub";
import Guides from "../../components/study-abroad-landing/Guides";
import ExploreKnowledgeHub from "../../components/study-abroad-landing/ExploreKnowledgeHub/ExploreKnowledgeHub";
import config from "../../util/config";
import { useLocation } from "react-router-dom";

export const StudyAbroadLanding = () => {
  const [studyAbroadData, setStudyAbroadData] = useState<any | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const location = useLocation();

  const params = new URLSearchParams(location.search);
  const selectedCountry = params.get("country");

  useEffect(() => {
    const fetchStudyAbroadData = async () => {
      try {
        const response = await axios.get(
          config.apiUrl + "/api/studyAbroad/landing-page"
        );
        setStudyAbroadData(response.data);
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };

    fetchStudyAbroadData();
  }, []);

  useEffect(() => {
    if (!loading) {
      const dreamCountrySection = document.getElementById("dream-country");
      if (dreamCountrySection) {
        dreamCountrySection.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [loading]);

  if (loading) {
    return <Loader />;
  }

  const {
    rankedByQs,
    mentors,
    successStories,
    faqs,
    guides,
    blogsAndArticles,
  } = studyAbroadData?.data || {};

  return (
    <div>
      <Banner />
      <CarosalLogos />
      <AboutEducationMentorsGlobally />
      <WhyStudyAbroadSec />
      <ChooseYourDreamCountry selectedCountry={selectedCountry || ""} />
      <UniversitiesWorldwideMentors connectToMentor={mentors} />
      <TopRankedUniversitiesQS rankedByQs={rankedByQs} />
      <SuccessStories successStories={successStories} />
      <ExploreKnowledgeHub blogsAndArticles={blogsAndArticles} />
      <KnowledgeHub faqData={faqs} />
      <Guides guides={guides} />
    </div>
  );
};
