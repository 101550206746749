import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";

import {
  sendOtp,
  signupPAFDetail,
  verifyOtp,
  Login,
} from "../thunks/authThunks";

interface AuthState {
  isAuthenticated: boolean;
  accessToken: string | null;
  user: any | null;
  mobile: string;
  otpSent: boolean;
  loading: boolean;
  error: any;
  message: string;
  [key: string]: any;
  pafData: any;
}

const pafData = {
  currentLevelOfStudy: ["8-11", "12th", "UG", "PG", "Others"],
  educationalBoards: [
    "State Board ",
    "CBSE",
    "IB",
    "IGCSE",
    "Cambridge",
    "NIOS",
    "others",
  ],
  percentageOptions: ["45-60", "60-75", "76-90", "90 Above"],
  lookingForDegree: ["No options available"],
  lookingForStream: [
    {
      streamId: 1,
      streamName: "Engineering",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/engineering.png",
    },
    {
      streamId: 2,
      streamName: "Management and Business Administration",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/project-management.png",
    },
    {
      streamId: 3,
      streamName: "Medicine and Allied Sciences",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/drugs.png",
    },
    {
      streamId: 4,
      streamName: "Law",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/auction.png",
    },
    {
      streamId: 5,
      streamName: "Animation and Design",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/vector.png",
    },
    {
      streamId: 6,
      streamName: "Media and Mass Communication and Journalism",
      streamIcon: "https://i.ibb.co/jVN6r9n/architecture.png",
    },
    {
      streamId: 7,
      streamName: "Arts, Humanities and Social Sciences",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/reporter.png",
    },
    {
      streamId: 8,
      streamName: "Sciences",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/chemistry.png",
    },
    {
      streamId: 9,
      streamName: "Commerce",
      streamIcon: "https://i.ibb.co/MhDMHWV/account.png",
    },
    {
      streamId: 10,
      streamName: "Computer Application and IT",
      streamIcon: "https://i.ibb.co/yn7PH4k/journalisam.png",
    },
    {
      streamId: 11,
      streamName: "Pharmacy",
      streamIcon:
        "https://cdn-icons-png.freepik.com/256/883/883407.png?semt=ais_hybrid",
    },
    {
      streamId: 12,
      streamName: "Education",
      streamIcon:
        "https://cdn-icons-png.freepik.com/256/4207/4207253.png?semt=ais_hybrid",
    },
    {
      streamId: 13,
      streamName: "Hospitality and Tourism",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/check-in.png",
    },
    {
      streamId: 14,
      streamName: "Competition",
      streamIcon: "https://i.ibb.co/0XkwYdc/Architecture.png[",
    },
    {
      streamId: 15,
      streamName: "School",
      streamIcon: "https://i.ibb.co/0XkwYdc/Architecture.png",
    },
    {
      streamId: 16,
      streamName: "Study Abroad",
      streamIcon: "https://i.ibb.co/0XkwYdc/Architecture.png",
    },
    {
      streamId: 17,
      streamName: "Architecture",
      streamIcon:
        "https://images.leverageedu.com/leverageapp/assets/CoursesImage/sketch.png",
    },
    {
      streamId: 18,
      streamName: "Uniformed Forces",
      streamIcon: null,
    },
    {
      streamId: 19,
      streamName: "Internships",
      streamIcon: null,
    },
  ],
  lookingForCourse: [],

  nextHigherEducation: ["Study India", "Study Abroad"],
  states: [
    {
      stateId: 2,
      stateName: "Maharashtra",
    },
    {
      stateId: 4,
      stateName: "Karnataka",
    },
    {
      stateId: 3,
      stateName: "Telangana",
    },
    {
      stateId: 3,
      stateName: "Telangana",
    },
  ],
  cities: [
    {
      cityId: 5,
      cityName: "Hyderabad",
    },
    {
      cityId: 4,
      cityName: "Pune",
    },
    {
      cityId: 5,
      cityName: "Hyderabad",
    },
    {
      cityId: 5,
      cityName: "Hyderabad",
    },
  ],
  factorstoChoose: [
    "Faculty",
    "Accreditations & Rankings",
    "Course Curriculum & Flexibility",
    "Placement and internships ",
    "International tie-ups & Exchange",
    "Fees & Scholarships",
    "Research & Innovations",
    "Start Ups & Entrepreneurship",
    "Infrastructure & Campus Life",
    "Location & Accessibility",
  ],
  educationFund: ["Self", "Loan", "Scholarship "],
  lookingForScholarship: [
    "Merit Scholarship",
    "Economically Weak",
    "Minority Scholarship",
    "Sports Scholarship",
  ],
  collegeCount: 0,
  univertiesCount: 4,
};

const initialState: AuthState = {
  isAuthenticated: false,
  accessToken: null,
  user: null,
  mobile: "",
  otpSent: false,
  loading: false,
  error: {},
  message: "",
  // pafData: null,
  pafData: pafData,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // login: (state, action: PayloadAction<{ token: string; user: string }>) => {
    //   state.isAuthenticated = true;
    //   state.token = action.payload.token;
    //   state.user = action.payload.user;
    // },
    logout: (state) => {
      state.isAuthenticated = false;
      state.token = null;
      state.user = null;
    },
  },
  extraReducers(builder) {
    //  send OTP
    builder.addCase(sendOtp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      console.log("SendOTP", action.payload);
      state.loading = false;
      state.otpSent = true;
      state.message = action.payload.message;
    });
    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    //  verify OTP
    builder.addCase(verifyOtp.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(verifyOtp.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.message = action.payload.message;

      // Store tokens in local storage
      if (action.payload.accessToken) {
        localStorage.setItem("accessToken", action.payload.accessToken);
      }
      if (action.payload.refreshToken) {
        localStorage.setItem("refreshToken", action.payload.refreshToken);
      }
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken || state.accessToken;
      state.refreshToken = action.payload.refreshToken || state.refreshToken;
      state.user = action.payload.user || state.user;
    });
    builder.addCase(verifyOtp.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });

    // signup after OTP verification
    builder.addCase(signupPAFDetail.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(signupPAFDetail.fulfilled, (state, action) => {
      console.log("signupPAFDetail", action);
      state.loading = false;
      state.pafData = action.payload;
      state.pafData = pafData;
      state.message = action.payload.message;
    });
    builder.addCase(signupPAFDetail.rejected, (state, action) => {
      state.loading = false;
    });

    //  Login OTP
    builder.addCase(Login.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(Login.fulfilled, (state, action) => {
      state.loading = false;
      state.isAuthenticated = true;
      state.message = action.payload.message;

      // Store tokens in local storage
      if (action.payload.accessToken) {
        localStorage.setItem("accessToken", action.payload.accessToken);
      }
      if (action.payload.refreshToken) {
        localStorage.setItem("refreshToken", action.payload.refreshToken);
      }
      localStorage.setItem("user", JSON.stringify(action.payload.user));
      state.isAuthenticated = true;
      state.accessToken = action.payload.accessToken || state.accessToken;
      state.refreshToken = action.payload.refreshToken || state.refreshToken;
      state.user = action.payload.user || state.user;
    });
    builder.addCase(Login.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload as string;
    });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
