import React, { useState, useRef, useEffect } from "react";
import UserIcon from "../images/authentication/user-circle-icon.svg";
import { Link, Navigate, useNavigate } from "react-router-dom";

interface UserAvatarProps {
  link: string;
  logoutUrl: string;
}

const UserAvatar: React.FC<UserAvatarProps> = ({ link, logoutUrl }) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const navigate = useNavigate();

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        dropdownRef.current &&
        !dropdownRef.current.contains(event.target as Node)
      ) {
        setIsOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleLogout = () => {
    localStorage.clear();

    navigate("/secure/login");
  };

  const handleDashboardClick = () => {
    navigate("/dashboard/my-profile"); // Absolute path
  };

  return (
    <div className="relative inline-block text-left" ref={dropdownRef}>
      <button
        onClick={toggleDropdown}
        className="flex items-center justify-center w-10 h-10 bg-gray-200 rounded-full focus:outline-none"
      >
        <div className="flex items-center justify-center w-12 h-12 ml-2 mr-1 ">
          <img src={UserIcon} className="text-gray-700 w-7 h-7" />
        </div>
      </button>

      {isOpen && (
        <div className="absolute right-0 z-40 w-48 py-1 mt-2 origin-top-right bg-[#00c798] rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
          <button
            // to={link}
            onClick={handleDashboardClick}
            className="block w-full px-4 py-2 text-sm text-left text-white hover:text-gray-700 hover:bg-gray-100"
          >
            My Profile
          </button>
          <button
            onClick={handleLogout}
            className="block w-full px-4 py-2 text-sm text-left text-white hover:text-gray-700 hover:bg-gray-100"
          >
            Logout
          </button>
        </div>
      )}
    </div>
  );
};

export default UserAvatar;
