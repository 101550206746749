import React, { useEffect } from "react";
import HeroSection from "./components/HeroSection";
import RevealFromBottom from "./components/RevealFromBottom";
// import RoadMap, { contentsType, imagesType } from "../../components/solutions/RoadMap";
// import HeroSection, { HeroSectionProps } from "../../components/HeroSection";
// import RevealFromBottom from "../../components/RevealFromBottom";
// import HeroTextHighlight from "../../assets/solutions/student-hero-text-highlight.svg"
import HeadingUnderline from "../../images/common/heading-underline.svg"
// import Contact from "../../components/solutions/Contact";

const heroSectionData = {
  headings: [
    <>We Educate</>,
    <>We Inspire</>,
    <>
      You{" "}
      <div className="relative inline-block">
        <div className="relative z-10 my-2 text-primary">Lead</div>
        <img
          //   src={HeroTextHighlight}
          className="absolute left-0 z-0 transform scale-125 -translate-y-1/2 top-1/2"
        />
      </div>
    </>,
  ],
  paragraphs: [
    <>
      Let your <span className="text-[#003FBC]">Dreams</span> lead the way
    </>,
    <>
      and your <span className="text-[#003FBC]">Degrees</span> light the path
    </>,
  ],
  image: "/images/solutions/students-banner.jpg",
};

const images = [
  {
    img: "/images/solutions/student-1.png",
    title: (
      <>
        Assessing <span className="text-[#003FBC]">21st-century</span> skills
      </>
    ),
  },
  {
    img: "/images/solutions/student-2.png",
    title: (
      <>
        Dual mentor <span className="text-[#003FBC]">Counselling</span>
        <br />
        Student mentor
      </>
    ),
  },
  {
    img: "/images/solutions/student-3.png",
    title: (
      <>
        Map my <span className="text-[#003FBC]">career path</span>
        <br />
      </>
    ),
  },
  {
    img: "/images/solutions/student-4.png",
    title: (
      <>
        Test <span className="text-[#003FBC]">Preparation</span>
      </>
    ),
  },
  {
    img: "/images/solutions/student-5.jpg",
    title: (
      <>
        <span className="text-[#003FBC]">Finance</span> your education
      </>
    ),
  },
  {
    img: "/images/solutions/student-6.jpg",
    title: (
      <>
        Enrolling at <span className="text-[#003FBC]">Dream University</span>
      </>
    ),
  },
];

const contents = [
  {
    heading: <>Advance 360&deg; Career Assessment Test</>,
    text: "Find the ideal career options based on your aptitude and interest in a scientific method.",
    tagLine: "Educate",
    isSubPoint: false,
  },
  {
    heading: "Research and discovery",
    text: "Get mentor guidance from our expert mentors on which career path would suit you best.",
    isSubPoint: false,
  },
  {
    heading: "Discover your perfect course",
    text: "Let College Mentor guide you to your ideal course and college for a bright future.",
    tagLine: "Inspire",
    isSubPoint: false,
  },
  {
    heading: "Application to Admission",
    text: "Find your real-time updates on exams and colleges.",
    isSubPoint: false,
  },
  {
    heading: "Loans and Scholarships",
    text: "Navigate loans and scholarships.",
    isSubPoint: false,
  },
  {
    heading: "Every journey needs a hero, be yours",
    text: "With guidance from your College Mentor, achieve success and flourish at your dream university.",
    tagLine: "Lead",
    isSubPoint: false,
  },
];

export default function AboutUs() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="overflow-hidden text-black">
      <section className="mb-12 sm:mb-24 sm:mt-12 md:mt-0 lg:mt-12">
        <HeroSection {...heroSectionData} />
      </section>
      <hr></hr>

      <div className="relative py-12 bg-white sm:py-24">
        <div className="container mx-auto">
          <div className="flex flex-col items-center">
            <RevealFromBottom>
              <h2 className="heading2">How it Works</h2>
              <img src={HeadingUnderline} className="mx-auto mb-2" />
            </RevealFromBottom>
            <h4 className="text-center heading4">[ A Plan For Every Dream ]</h4>
            <p className="text-center text1">
              Identifying your career goals and formulate a step by step plan.
            </p>
          </div>
        </div>
        <div className="container max-w-screen-xl mx-auto mt-12 sm:mt-24">
          <div role="list" className="flex">
            {/* <RoadMap images={images} contents={contents} /> */}
          </div>
        </div>
      </div>
      {/* <Contact /> */}
    </div>
  );
}
