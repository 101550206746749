import React, { useState } from "react";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { RootState } from "../../../../store/store";
import {
  nextStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OptionGroup from "./common/OptionsCard/OptionGroup";
import { signupPAFDetail } from "../../../../store/thunks/authThunks";
import axios from "axios";
import config from "../../../../util/config";

export const PERFERRED_STATES: string[] = [
  "Delhi",
  "Mumbai",
  "Telangana",
  "TamilNadu",
];

const PreferToStudy = () => {
  const dispatch = useDispatch<any>();
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    currentLevelOfStudy,
    course,
    degree,
    stream,
    city,
    state,
    otp,
  } = useSelector((state: RootState) => state.registrationFormSlice.data);
  const { pafData } = useSelector((state: RootState) => state.auth);

  const { register, handleSubmit } = useForm();

  const [selectedCard, setSelectedCard] = useState<any>("");

  const [selectedSingle, setSelectedSingle] = useState<any>(null);

  const onSubmit = (data: any) => {
    // dispatch(updateForm({ preferredStates: selectedCard }));
    dispatch(updateForm({ state: selectedSingle }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleSingleSelectionChange = (selected: any) => {
    setSelectedSingle(selected);
  };
  let streamName = stream?.streamId.toString();
  let courseName = course?.courseName;
  const registerUserDetails = async (state: any) => {
    debugger;
    let stateName = state?.stateId;
    let payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      // password: "string",
      mobileNumber: mobileNumber,
      currentLevelOfStudy: currentLevelOfStudy,
      lookingForDegree: "degree",
      lookingForStream: streamName,
      lookingForCourse: courseName,
      state: "6",
      city: "5",
      otp: otp,
    };

    try {
      const response = await axios.post(
        config.apiUrl + "/api/auth/user/userExplore",
        payload,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      // setResponseData(response.data.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching user explore data:", error);
      // setError("An error occurred while fetching data.");
    } finally {
      // setLoading(false);
    }
  };

  const handleNext = () => {
    debugger;
    dispatch(updateForm({ state: selectedCard }));
    registerUserDetails(selectedCard);
    // dispatch(
    //   signupPAFDetail({
    //     streamId: selectedSingle?.streamId,
    //     courseId: 0,
    //     state: 0,
    //     city: 0,
    //   })
    // )
    //   .unwrap()
    //   .then(() => {
    //     dispatch(nextStep());
    //   })
    //   .catch((err: any) => {
    //     console.error("OTP verification failed:", err);
    //   });
  };
  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={false}
        showBack={true}
        stepName="Where do you prefer to study?"
        stepDescription=""
      >
        <div className="w-full p-4 overflow-auto h-21rem">
          {/* Old Implementation */}
          {/* <OptionGroup
            mode={"multiple"}
            options={PERFERRED_STATES}
            handleCardSelect={handleCardClick}
            cardSize={"40"}
          /> */}

          {/* <OptionGroup
            options={pafData.states}
            mode="multiple"
            getTitle={(option) =>
              typeof option === "string" ? option : option.stateName
            }
            handleCardSelect={handleCardClick}
            cardSize={"50"}
          /> */}

          <OptionGroup
            options={pafData.states}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.stateName
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="auto"
          />
          {/* <h3 className="mt-4">Selected Single Options (Full Objects):</h3>
          <pre>{JSON.stringify(selectedSingle, null, 2)}</pre> */}
        </div>
      </StepNavigationWrapper>
      <div className="flex justify-center">
        <button
          type="button"
          onClick={handleNext}
          className="transition duration-0 hover:duration-150 p-3 border rounded-full bg-[#113cc0] text-white hover:bg-[#113cc0] hover:text-white  ease-in-out"
          style={{ padding: "8px 18px" }}
        >
          Continue
        </button>
      </div>
    </div>
  );
};

export default PreferToStudy;
