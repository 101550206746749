import React, { useState, useEffect } from "react";

export default function IndiasTopInstitutions() {
  const imageLinks = [
    {
      id: 1484,
      imageUrl: "/images/universities/Bennett.png",
      redirectPath: "/college-details",
    },
    {
      id: 1485,
      imageUrl: "/images/universities/bml-logo.png",
      redirectPath: "/college-details",
    },
    {
      id: 1486,
      imageUrl: "/images/universities/Gitam-University.png",
      redirectPath: "/college-details",
    },
    {
      id: 1487,
      imageUrl: "/images/universities/chandigarh-university.png",
      redirectPath: "/college-details",
    },
    {
      id: 1488,
      imageUrl: "/images/universities/amrita-logo.png",
      redirectPath: "/college-details",
    },
    {
      id: 1489,
      imageUrl: "/images/universities/KIIT-University.webp",
      redirectPath: "/college-details",
    },
    {
      id: 1490,
      imageUrl: "/images/universities/kl-university-logo.png",
      redirectPath: "/college-details",
    },
    {
      id: 1491,
      imageUrl: "/images/universities/LPU-logo.png",
      redirectPath: "/college-details",
    },
    {
      id: 1492,
      imageUrl: "/images/universities/manav-rachna.png",
      redirectPath: "/college-details",
    },
    {
      id: 1493,
      imageUrl: "/images/universities/satya-bhama.jpg",
      redirectPath: "/college-details",
    },
    {
      id: 1494,
      imageUrl: "/images/universities/saveetha-university.png",
      redirectPath: "/college-details",
    },
    {
      id: 1495,
      imageUrl: "/images/universities/UPES-logo.png",
      redirectPath: "/college-details",
    },
    {
      id: 1496,
      imageUrl: "/images/universities/Woxsen-logo.png",
      redirectPath: "/college-details",
    },
    {
      id: 1497,
      imageUrl: "/images/universities/sathaya.png",
      redirectPath: "/college-details",
    },
  ];

  const repeatedImageLinks = Array.from({ length: 5 }, () => imageLinks).flat(); // Repeat 5 times

  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex(
        (prevIndex) => (prevIndex + 1) % repeatedImageLinks.length
      );
    }, 5000); // Change the interval duration as needed (e.g., every 5 seconds)

    return () => clearInterval(interval);
  }, [repeatedImageLinks]);

  return (
    <div className="universities-section container">
      <div className="flex flex-row max-md:flex-col justify-center">
        <div className="text-center justify-center md:w-[10%] text-sm font-medium text-black max-md:w-[70%] my-auto self-center">
          Supported ByIndia’s Top Institutions(as Application Partner)
        </div>
        <div className="flex gap-5 justify-between px-5 mt-4 max-md:flex-wrap">
          <div className="carousel">
            <div className="slider">
              <div
                className="slidetrack"
                style={{
                  transform: `translateX(-${currentImageIndex * 100}%)`,
                }}
              >
                {repeatedImageLinks.map((item, index) => (
                  <div key={index} className="slide">
                    <a href={item.redirectPath}>
                      <img
                        loading="lazy"
                        src={item.imageUrl}
                        alt="university"
                        className="h-full w-full object-contain"
                        width={255}
                        height={96}
                      />
                    </a>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
