import React from "react";
interface BenefitItem {
  title: string;
  description: string;
}

const ScholarshipDisbursement = () => {
  const benefits: BenefitItem[] = [
    {
      title: "Tuition fees",
      description: "will be disbursed directly to institutions.",
    },
    {
      title: "Cash prizes",
      description: "will be transferred to the winners' bank accounts.",
    },
    {
      title: "Enjoy a ₹10,000 scholarship",
      description:
        "applied at the time of admission to partner universities, exclusively through the College Mentor admission process. Scholarship scores will be prioritized in the admission process, with a list of participating universities provided upon results.",
    },
  ];

  const BenefitList: React.FC<{ benefits: BenefitItem[] }> = ({ benefits }) => {
    return (
      <ul>
        {benefits.map((benefit, index) => (
          <li key={index}>
            <span className="font-semibold">{benefit.title}</span>
            {` ${benefit.description}`}
          </li>
        ))}
      </ul>
    );
  };
  return (
    <div className="px-20 py-10 w-full bg-[#EFF] max-md:px-7 max-md:w-full my-6">
      <div className="flex gap-5 max-md:flex-col-reverse">
        <div className="flex flex-col w-[51%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src="/images/scholorshiptest/scholorship-disbusrement.jpg"
            alt="Eligibility Information"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-md:mt-10 max-md:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 ml-5 w-[49%] max-md:ml-0 max-md:w-full my-auto leading-[30px] md:p-4 max-md:mx-auto">
          <h3 className="text-[#173CBA] text-[48px] font-light max-md:text-[24px]">
            Scholarship Disbursement
          </h3>
          <div>
            <p className="p-2">
              Join the exclusive World Education Tour to Europe! Experience rich
              cultures, gain invaluable insights, and expand your horizons.
              Don't miss this chance to elevate your education.
            </p>
            <div className="mt-5 pl-7">
              <BenefitList benefits={benefits} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholarshipDisbursement;
