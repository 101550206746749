import React from "react";

const ScholorshipEligibility = () => {
  return (
    <div className="px-20 py-10 w-full bg-[#FFF1FA] max-md:px-7 max-md:w-full my-6">
      <div className="flex gap-10 max-md:flex-col-reverse">
        <div className="flex flex-col w-[51%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src="/images/scholorshiptest/sc-eligibility.jpg"
            alt="Eligibility Information"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-md:mt-10 max-md:max-w-full"
          />
        </div>
        <div className="flex flex-row max-md:flex-col max-md:gap-5 w-[49%] max-md:ml-0 max-md:w-full my-auto justify-between">
          <div className="flex flex-col w-[324px] max-md:w-full gap-5">
            <div className="p-[15px] text-base border border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
              <h2 className="text-base font-semibold ">
                Eligibility Criteria:
              </h2>
              <ul className="list-disc list-inside p-3">
                <li>Must be an Indian citizen.</li>
                <li>
                  Currently studying in Class 12 or equivalent from a recognized
                  board in India.
                </li>
              </ul>
            </div>
            <div className="p-[15px] text-base border border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
              <h2 className="text-base font-semibold ">
                Scholarship Test Pattern:
              </h2>
              <ul className="list-disc list-inside space-y-2 font-normal">
                <li>
                  <strong>Stream : </strong>Science (PCM)
                </li>
                <li>
                  <strong>Format:</strong> Objective (MCQs)
                </li>
                <li>
                  <strong>Total Questions:</strong> 45
                </li>
                <li>
                  <strong>Duration:</strong> 60 minutes
                </li>
                <li>
                  <strong>Maximum Marks:</strong> 135
                </li>
                <li>
                  <strong>Scoring:</strong> +3 for correct answers, -1 for
                  incorrect answers
                </li>
              </ul>
            </div>
          </div>
          <div className="flex flex-col w-[284px] max-md:w-full gap-5">
            <div className="pl-[25px] pr-[35px] py-[15px] text-base border border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
              <h2 className="text-base font-semibold ">Important Dates:</h2>
              <ul className="list-disc list-inside space-y-2 font-normal ">
                <li>
                  <strong>Registration Start:</strong> <br />
                  November 10, 2024
                </li>
                <li>
                  <strong>Registration End:</strong> <br />
                  December 31, 2025
                </li>
                <li>
                  <strong>Test Dates:</strong> <br />
                  January 1-18, 2025
                </li>
                <li>
                  <strong>Results Announcement:</strong>
                  <br /> February 3, 2025
                </li>
              </ul>
            </div>
            <div className="p-[15px] text-base border text-black border-[#F6C] bg-white shadow-[0px_0px_15px_4px_rgba(255,102,204,0.25)]  rounded-lg">
              <p>
                <strong>Note :</strong> Scholarship test and rewards available
                exclusively for students who are domiciled in Andhra Pradesh,
                Telangana, and Tamil Nadu Only.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholorshipEligibility;
