import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../store/store";

const PREFERENCE_SCHEMA = [
  { name: "Stream", key: "stream" },
  { name: "Level", key: "level" },
  { name: "Specialization", key: "specialization" },
  { name: "Location", key: "location" },
  { name: "College Type", key: "collegeType" },
  { name: "Fee Range", key: "feeRange" },
  { name: "Colleges", key: "colleges" },
  { name: "Interested in studying abroad", key: "interestedStudyingAbroad" },
  { name: "Need a loan?", key: "needLoan" },
];

const PreferenceEditor = () => {
  const [showEditPopup, setShowEditPopup] = useState(false);

  const preferencesData = useSelector(
    (state: RootState) => state.myProfile.userData?.preferences
  );

  if (!preferencesData) return;

  return (
    <div className="bg-white rounded-lg p-2">
      <div className="flex items-center justify-between p-2">
        <h2 className="text-xl font-bold">Preferences</h2>
        <button>
          <FontAwesomeIcon
            icon={faEdit}
            onClick={() => setShowEditPopup(true)}
          />
        </button>
      </div>
      <div className="p-2 grid grid-cols-2 lg:grid-cols-3 gap-4">
        {PREFERENCE_SCHEMA.map((preference) => {
          return (
            <div>
              <div>{preference.name}</div>
              <div className="text-ellipsis font-bold overflow-hidden">
                {(!preferencesData[preference.key] ||
                  preferencesData[preference.key]?.length == 0) &&
                  "No Preferences"}
                {preferencesData[preference.key]?.length == 1 &&
                  preferencesData[preference.key][0]}
                {preferencesData[preference.key]?.length > 1 && (
                  <ol className="list-number">
                    {preferencesData[preference.key].map((item: any) => (
                      <li>{item}</li>
                    ))}
                  </ol>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default PreferenceEditor;
