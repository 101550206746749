import React from "react";

interface PricingFeature {
  title: string;
  description: string;
}

interface PricingPlan {
  title: string;
  color: string;
  icon: string;
  features: PricingFeature[];
  price: string;
  sessions: string;
  mentors: string;
}

const pricingPlans: PricingPlan[] = [
  {
    title: "Educate",
    color: "text-[#0c9]",
    icon: "/images/career-discovery/price-icon1.png",
    features: [
      {
        title: "Stream Assessment",
        description:
          "3-dimensional stream and subject assessment with top 2 stream recommendations.",
      },
      {
        title: "Career Webinars",
        description:
          "Access to well-researched information on hundreds of career options.",
      },
      {
        title: "15 - Page Stream Report",
        description:
          "Detailed assessment report containing best-fit stream matches and personalized development plans.",
      },
      {
        title: "Career Mentor Counseling",
        description:
          "One face-to-face counseling session with a Career Mentor or Admission Mentor",
      },
    ],
    price: "1,999",
    sessions: "1 Session",
    mentors: "1 Mentor",
  },
  {
    title: "Inspire",
    color: "text-[#FF66CC]",
    icon: "/images/career-discovery/price-icon2.png",
    features: [
      {
        title: "Career Assessment",
        description:
          "3-dimensional assessment with tailored recommendations for your top 3 career options",
      },
      {
        title: "Career Webinars",
        description: "Comprehensive information on a variety of career paths",
      },
      {
        title: "25 - Page Career Report",
        description:
          "In-depth report featuring top career matches and personalized development plans",
      },
      {
        title: "Career Book",
        description:
          "The Ultimate Career Navigator Your Guide to Colleges, Careers, and Scholarships",
      },
      {
        title: "Career Counseling",
        description:
          "One-on-one online counseling sessions, including 6 different Mentoring sessions and ongoing support from College Mentor experts",
      },
      {
        title: "Career Mentor",
        description:
          "Personalized mentoring, goal monitoring, admission guidance, and continuous support",
      },
    ],
    price: "9,999",
    sessions: "1 Session",
    mentors: "6 Mentor",
  },
  {
    title: "Lead",
    color: "text-[#173CBA]",
    icon: "/images/career-discovery/price-icon3.png",
    features: [
      {
        title: "Comprehensive Career Assessment",
        description:
          "In-depth assessment with tailored recommendations for 3 career paths.",
      },
      {
        title: "Extensive Career Webinars",
        description:
          "Access to detailed information on a wide range of career options.",
      },
      {
        title: "25 - Page Career Report",
        description:
          "A thorough report highlighting career matches and development plans.",
      },
      {
        title: "Career Book",
        description:
          "The Ultimate Career Navigator - Your guide to colleges, careers, and scholarships.",
      },
      {
        title: "Career Counseling",
        description:
          "Multiple face-to-face 6 mentor counseling sessions, plus ongoing online support.",
      },
      {
        title: "Exam Library",
        description:
          "Comprehensive resources for exam preparation, including mock tests, past papers, performance estimation tools, and support.",
      },
      {
        title: "Admission Mentor",
        description:
          "A dedicated mentor to guide you through admissions, scholarships, and loans for studying in India or abroad.",
      },
    ],
    price: "19,999",
    sessions: "3 Session",
    mentors: "6 Mentor",
  },
];

export const IkigaiPricingPlan: React.FC = () => {
  return (
    <div className="flex flex-col px-20 pt-6 bg-[#F1F1F1] pb-14 w-full  max-md:px-5 max-md:pt-14 max-md:max-w-full">
      <h3 className="text-center text-[#173CBA] text-[40px] font-light ">
        College Mentor Packages Plans and Pricing
      </h3>
      <p className="text-black text-[24px] font-normal text-center">
        Our insightful services for you <br />
        Expert Mentoring on Admissions to Universities in India and Abroad
      </p>
      <div className="flex flex-wrap gap-5 w-full max-md:flex-col mt-10">
        {pricingPlans.map((plan, index) => (
          <div
            key={index}
            className="flex flex-col items-center w-[32%] max-md:w-full"
          >
            {/* Top Section */}
            <div
              className={`flex items-center gap-3.5 px-24 py-2.5 ${plan.color} bg-white border border-solid border-current shadow-[0px_0px_10px_rgba(0,0,0,0.15)] max-md:px-10 rounded-t-lg relative z-10`}
              style={{ bottom: "10px", borderRadius: "0px 0px 10px 10px" }}
            >
              <img
                loading="lazy"
                src={plan.icon}
                alt={`${plan.title} icon`}
                className="object-contain shrink-0 w-11 aspect-square"
              />
              <div className="my-auto text-3xl font-medium">{plan.title}</div>
            </div>

            {/* Bottom Section */}
            <div
              style={{ borderRadius: "10px 10px 0px 0px" }}
              className={`flex flex-col pt-20 pb-0.5 w-full justify-between bg-white border border-solid ${plan.color.replace(
                "text",
                "border"
              )} relative -mt-10 z-0 h-[900px]`}
            >
              <div className="flex flex-col mx-8 text-base leading-6 text-black max-md:mx-2.5">
                {plan.features.map((feature, fIndex) => (
                  <div key={fIndex} className="mt-4 first:mt-0">
                    <span className={`font-bold ${plan.color}`}>
                      {feature.title}
                    </span>
                    <br />
                    {feature.description}
                  </div>
                ))}
                <div
                  className={`mt-4 text-xl font-semibold leading-none ${plan.color}`}
                >
                  Price: ₹ {plan.price}
                </div>
              </div>

              <div className="flex flex-col items-center mt-auto">
                <div className="self-center mt-6 text-sm leading-6 text-center text-black">
                  Number of counselling sessions
                </div>
                <div className="shrink-0 mx-7 mt-1.5 max-w-full h-0 border border-black border-solid w-[356px] max-md:mx-2.5" />
                <div className="flex gap-10 self-center mt-1.5 max-w-full w-[328px]">
                  <div className="flex flex-1 gap-2 text-sm leading-6 text-zinc-800">
                    <div className="flex shrink-0 my-auto rounded-full border border-red-600 border-solid h-[15px] w-[15px]" />
                    <div>{plan.sessions}</div>
                  </div>
                  <div className="flex flex-1 gap-2">
                    <div className="flex flex-col justify-center p-1 my-auto rounded-full border border-red-600 border-solid">
                      <div className="flex shrink-0 bg-red-600 rounded-full h-[9px] w-[9px]" />
                    </div>
                    <div className="text-sm leading-6 text-zinc-800">
                      {plan.mentors}
                    </div>
                  </div>
                </div>
                <button
                  className={`px-16 py-3 mt-1.5 text-xl  w-full mb-[-2px] font-semibold leading-none text-center text-white ${plan.color.replace(
                    "text",
                    "bg"
                  )} max-md:px-5`}
                >
                  Schedule Your Free Call Now
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
