import React, { useEffect, useRef, useState } from "react";
import AuthHeader from "./components/common/AuthHeader";
import RegistrationBgImage from "../../../../images/authentication/auth-bg.svg";
import MultiStepForm from "./components/MultiStepForm";

import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../store/store";
import StepProgressBar from "./components/common/Animated/StepProgressBar";
import { nextStep } from "../../../store/slices/registrationFormSlice";
import planeSvg from "../../../images/authentication/plane-svg.svg";
import CurvedProgressBar from "./components/common/Animated/CurvedProgressBar";
import FramerAnime from "./components/common/Animated/FramerAnime";
import FlightAnimation from "./components/common/Animated/FlightAnimation";
const RevampedRegistration = () => {
  const dispatch = useDispatch<any>();
  const { step, direction } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const currentStep = step;
  const totalSteps = 9;

  const percentage = (step / 6) * 100;

  // CANVAS IMPLEMENTAION

  const canvasRef = useRef<HTMLCanvasElement>(null); // Ref for the canvas

  // Load the SVG image from the local path

  const svgIcon = `
    <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 24 24" fill="green">
      <path d="M20.285 6.315l-10.285 10.293-6.285-6.287 1.415-1.413 4.871 4.877 8.871-8.877 1.413 1.407z"/>
    </svg>
  `;
  const svgImage = new Image();
  // svgImage.src = "data:image/svg+xml;base64," + btoa(svgIcon);
  svgImage.src = planeSvg;

  const drawProgressBar = () => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    const radius = 20; // Radius of the step circles
    const margin = 40; // Margin between the circles
    const startX = 50; // Starting x position
    const startY = canvas.height - 30; // Starting y position for the parabola

    // Clear the canvas before drawing
    ctx.clearRect(0, 0, canvas.width, canvas.height);

    // Draw the entire upside-down parabola for the progress line
    const endX = startX + (totalSteps - 1) * (radius * 2 + margin); // End x position
    const controlX = startX + ((totalSteps - 1) * (radius * 2 + margin)) / 2; // Control point for x
    const controlY = startY - 60; // Control point height to adjust the curve

    ctx.beginPath();
    ctx.moveTo(startX, startY); // Starting point
    ctx.quadraticCurveTo(controlX, controlY, endX, startY); // Draw the parabola
    ctx.strokeStyle = "#CCC"; // Default stroke color
    ctx.lineWidth = 5;
    ctx.stroke();

    // Draw the filled progress up to the current step
    const filledEndX = startX + currentStep * (radius * 2 + margin); // End position for filled part
    ctx.beginPath();
    ctx.moveTo(startX, startY); // Starting point
    ctx.quadraticCurveTo(controlX, controlY, filledEndX, startY); // Draw the filled parabola
    ctx.strokeStyle = "#4CAF50"; // Green for completed
    ctx.lineWidth = 5;
    ctx.stroke();

    // Draw the steps (circles)
    for (let i = 0; i < totalSteps; i++) {
      const x = startX + i * (radius * 2 + margin);
      ctx.beginPath();
      ctx.arc(x, startY, radius, 0, Math.PI * 2);
      ctx.fillStyle = i <= currentStep ? "#4CAF50" : "#CCC"; // Green for completed, gray for pending
      ctx.fill();
      ctx.strokeStyle = "#000"; // Outline color
      ctx.lineWidth = 2;
      ctx.stroke();
    }

    // Position of the moving object
    const movingObjectX = startX + currentStep * (radius * 2 + margin);
    ctx.drawImage(
      svgImage,
      movingObjectX - radius,
      startY - radius * 2,
      radius * 2,
      radius * 2
    );
  };

  // Use useEffect to redraw the progress bar every time currentStep changes
  useEffect(() => {
    drawProgressBar();
  }, [currentStep]);

  useEffect(() => {
    localStorage.removeItem("paf_latest");
  }, []);

  return (
    <div
      className="relative flex flex-col min-h-screen bg-[#fff] bg-no-repeat bg-bottom"
      //   style={{
      //     backgroundImage: "url('../../../../images/authentication/auth-bg.svg')",
      //     backgroundSize: "contain",
      //   }}
    >
      {/* Auth Header */}
      <AuthHeader />

      {/* Main Content Section */}
      <div className="z-10 flex justify-center flex-1 p-4 md:items-center ">
        <div className="flex flex-col w-full gap-x-4 md:flex-row gap-y-4 md:items-start">
          {/* Information Section - Hidden on Mobile */}
          {/* <div className="hidden w-1/3 h-auto p-6 bg-[#00c697] rounded-lg shadow-md md:block gap-4">
            <div className="flex items-center p-2 mb-4 space-x-4 bg-white rounded-md shadow max-w-max">
              <div className="flex flex-col items-center">
                <span className="text-lg font-semibold text-gray-800">4</span>
                <span className="text-sm text-gray-600">Universities</span>
              </div>

              <div className="h-8 border-l border-gray-400"></div>

              <div className="flex flex-col items-center">
                <span className="text-lg font-semibold text-gray-800">5</span>
                <span className="text-sm text-gray-600">Courses</span>
              </div>
            </div>
            <h2 className="mb-4 text-xl font-semibold text-white">
              Let’s help you find your dream Career
            </h2>
            <p className="text-white">
              Here you can include any important information or guidelines
              related to the registration process.
            </p>
          </div> */}

          <div
            className="sm:h-auto md:h-[35rem] p-6 bg-[#00c697] rounded-lg shadow-md md:block md:w-2/6 relative bg-bottom"
            style={{
              //   backgroundImage:
              //     "url('https://assets.admitkard.com/images/generic_banner.svg')",
              backgroundImage:
                "url('../../../../images/authentication/auth-bg.svg')",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
            }}
          >
            {/* Small Card with Rows for Universities and Courses */}
            <div className="flex items-center p-2 mb-4 space-x-4 bg-gray-100 rounded-md shadow max-w-max">
              {/* Universities Column */}
              <div className="flex flex-col items-center">
                <span className="text-3xl text-gray-800">400</span>
                <span className="text-sm text-gray-600">Universities</span>
              </div>

              {/* Vertical Line */}
              <div className="h-8 border-l border-gray-400"></div>

              {/* Courses Column */}
              <div className="flex flex-col items-center">
                <span className="text-3xl text-gray-800">103349</span>
                <span className="text-sm text-gray-600">Courses</span>
              </div>
            </div>

            {/* Information Section Content */}
            <h2 className="mb-4 text-xl text-white">
              Let’s help you find your dream Career,Course, Career, Exam and
              Campus
            </h2>
            {/* <p className="text-white">
              Here you can include any important information or guidelines
              related to the registration process.
            </p> */}
            {/* <canvas
              ref={canvasRef}
              width="400"
              height="100"
              style={{ marginBottom: "20px" }}
            ></canvas> */}

            {/* <StepProgressBar
              currentStep={currentStep - 1}
              totalSteps={totalSteps}
            /> */}
            <CurvedProgressBar currentStep={currentStep} steps={totalSteps} />
            <FlightAnimation step={currentStep-1} steps={totalSteps+1}/>

            {/* <FramerAnime currentStep={currentStep-1} steps={totalSteps} /> */}
          </div>

          {/* Stepper Section */}
          <div className=" p-6 bg-[#e6f9f5] rounded-lg shadow-md w-auto max-h-max md:min-h-0">
            <MultiStepForm />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RevampedRegistration;
