import axios from "axios";
import { FC, useEffect, useState } from "react";
import parse from "html-react-parser";
import BlogCard from "../../../components/blogs-and-articles/BlogCard";
import VideoCard from "../../../components/blogs-and-articles/VideoCard";
import VideoModal from "../../../components/blogs-and-articles/VideoModal";
import VisualStoryCard from "../../../components/blogs-and-articles/VisualStoryCard-new";
import WebinarCard from "../../../components/blogs-and-articles/WebinarCard";
import CustomSwiper from "../../../components/Slider/CustomSwiper";
import VisualStory from "../../../components/VisualStory";
import LeadGeneration from "../../../components/LeadGeneration";

interface IProps {
  apiURL: string;
}

const TabContent: FC<IProps> = ({ apiURL }) => {
  const [data, setData] = useState<any>(null);

  useEffect(() => {
    const fetchData = async () => {
      const response = await axios.get(apiURL);
      setData(response.data);
    };

    fetchData();
  }, [apiURL]);

  if (!data) return;

  return (
    <>
      <section className="py-12">
        <div className="container">
          {Object.entries(data).map(([key, value]) => {
            if (typeof value == "string")
              return (
                <div className="html-content">
                  {/* <h1 className="text-red text-2xl font-bold">HEADING: {key}</h1> */}
                  {parse(value || "")}
                </div>
              );
          })}
        </div>
      </section>
      {data?.blogsAndArticles && (
        <WebinarAndArticles data={data.blogsAndArticles} />
      )}
    </>
  );
};

export default TabContent;

const WebinarAndArticles: FC<{
  data: {
    webinar: any[];
    articles: any[];
    spotLight: any[];
    news: any[];
    visualStories: any[];
  };
}> = ({ data }) => {
  const [activeTabIndex, setActiveTabIndex] = useState(0);
  const changeActiveTab = (index: number) => setActiveTabIndex(index);
  const [leadGenerationPopupIsOpen, setLeadGenerationPopupIsOpen] =
    useState(false);
  const [videoModalURL, setVideoModalURL] = useState<string | null>(null);
  const [visualStoryIndex, setVisualStoryIndex] = useState<number>(-1);

  if (!data) return;

  const isVisualStoryOpen =
    visualStoryIndex >= 0 && visualStoryIndex < data?.visualStories.length;

  const tabs = [
    {
      name: "Webinar",
      content: data.webinar.map((data) => (
        <WebinarCard
          {...data}
          handlePopup={() => setLeadGenerationPopupIsOpen(true)}
        />
      )),
    },
    {
      name: "Articles",
      content: data.articles.map((data) => <BlogCard {...data} />),
    },
    {
      name: "Spotlights",
      content: data.spotLight.map((data) => (
        <VideoCard
          {...data}
          openVideoModal={() => setVideoModalURL(data.link)}
        />
      )),
    },
    { name: "News", content: data.news.map((data) => <BlogCard {...data} />) },
    {
      name: "Visual Stories",
      content: data.visualStories.map((data, index) => (
        <VisualStoryCard
          {...data}
          openVisualStory={() => setVisualStoryIndex(index)}
        />
      )),
    },
  ];

  return (
    <>
      <div className="mt-12">
        <div className="flex justify-center text-grey">
          {tabs.map((tab, index) => (
            <button
              className={`text-xs lg:text-sm px-4 py-2 ${
                activeTabIndex == index
                  ? "bg-blue text-white font-semibold"
                  : ""
              }`}
              onClick={() => changeActiveTab(index)}
            >
              {tab.name}
            </button>
          ))}
        </div>
        <div className="bg-blue">
          <div className="container py-12">
            <CustomSwiper
              items={tabs[activeTabIndex].content}
              navigation={"bottom"}
              autoplay={true}
              breakpoints={{
                320: { slidesPerView: 1, spaceBetween: 20 },
                768: { slidesPerView: 2, spaceBetween: 20 },
                1024: { slidesPerView: 3, spaceBetween: 20 },
                1280: { slidesPerView: 4, spaceBetween: 30 },
              }}
              progressBar={true}
            />
          </div>
        </div>
      </div>
      {videoModalURL && (
        <VideoModal
          link={videoModalURL}
          closeModal={() => setVideoModalURL(null)}
        />
      )}
      <VisualStory
        isOpen={isVisualStoryOpen}
        slides={
          (isVisualStoryOpen &&
            getVisualStorySlides(
              data?.visualStories[visualStoryIndex].slides
            )) ||
          []
        }
        handleClose={() => {
          setVisualStoryIndex(-1);
        }}
      />
    </>
  );
};

const getVisualStorySlides = (
  slides: {
    title: string;
    description: string;
    image: string;
  }[]
) => {
  return slides.map((slide) => ({
    image: slide.image,
    content: (
      <div className="p-4">
        <h1 className="text-xl text-white font-bold text-center">
          {slide.title}
        </h1>
        <p className="mt-2 text-base text-white text-justify">
          {slide.description}
        </p>
      </div>
    ),
  }));
};
