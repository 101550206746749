import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import Loader from "./Loader/Loader";

const LeadGeneration = () => {
  const { isLoading, isSuccessful, isAlertOpen } = useSelector(
    (state: RootState) => state.leadGen
  );

  if (isLoading)
    return (
      <div className="fixed inset-0 bg-[#000000dd] z-[1000]">
        <Loader />
      </div>
    );
  else if (isAlertOpen && isSuccessful)
    return (
      <div className="fixed top-12 left-1/2 -translate-x-1/2 px-4 py-2 rounded bg-green text-white z-[2000000]">
        Request sent successfully!
      </div>
    );
  else if (isAlertOpen)
    return (
      <div className="fixed top-12 left-1/2 -translate-x-1/2 px-4 py-2 rounded bg-red text-white z-[2000000]">
        Error sending request!
      </div>
    );
};

export default LeadGeneration;
