import React, { useEffect, useMemo, useState } from "react";
import TrendingTopics from "../components/TrendingTopics";
import BlogsBanner from "../components/BlogsAdBanner";
import BlogsBreadcrumb from "../components/BlogsBreadcrumb";
import Blog from "./Blog";
import RelatedBlogs from "./sections/RelatedBlogs";
import { GetWindowDimensions } from "../../../hooks/window-hooks/WindowHooks";
import BannerImage1 from "../../../images/blog-article/blogsAdBanner.png";
import AdityaVertical from "../../../images/blog-article/Aditya-Vertical.png";
import AmritaVertical from "../../../images/blog-article/Amrita-Vertical.png";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import Loader from "../../../components/Loader/Loader";
import addTopMechanicalEngineering from "../../../images/courses/top-mechanical-add.svg";
import config from "../../../util/config";
import NextBlog from "./sections/NextBlog";
import AboutAuthor from "./sections/AboutAuthor";
import moment from "moment";
import PrevOrNextBlog from "./sections/NextBlog";
import { getBlogOrNewsUrl } from "../utils";
import CustomSwiper from "../../../components/Slider/CustomSwiper";
import { breadcrumbs } from "@material-tailwind/react";
import "./BlogDetails.scss";
interface Breadcrumb {
  newsTitle: { title: string; slug: string };
  newsSubTopic: { title: string; slug: string };
  newsTopic: { title: string; slug: string };
  home: { title: string; slug: string };
  stream: { title: string; slug: string };
}
const title = { title: "string" };
const followUsContent = (
  <>
    <hr />
    <div className="items-center py-2 mr-2 text-xs capitalize xl:text-sm mb-10px">
      <span className="mr-1 leading-4 xl:leading-18px">By</span>
      <a className="mr-1 font-bold xl:leading-18px leading-2">
        College Mentor,
      </a>
    </div>

    <p className="italic">
      Follow{" "}
      <a href="/" className="text-[#173CBA]">
        collegementor.com{" "}
      </a>
      for latest education news in detail on Application forms, Exam dates
      ,Admit Cards, & Schedules, Exam Results, Counselling dates, Colleges &
      Universities news related to Admissions & Courses, Careers, Board exams,
      Scholarships, Placements, Internships, Online Education Events, New
      education policies & Regulations.
    </p>
    <p className="mt-2 italic ">
      To get in touch with college mentor news team, please write to us at{" "}
      <a href="mailto:adminnews@collegementor.com" className="text-[#173CBA]">
        adminnews@collegementor.com
      </a>
    </p>
  </>
);

const BlogDetails = () => {
  const bannerImage2 = "/images/blogsBanner.png";

  const location = useLocation();
  const category = location.pathname.split("/").filter(Boolean)[0];
  let { topicSlug, titleSlug } = useParams();

  const { width } = GetWindowDimensions();

  const navigate = useNavigate();

  const [blogResponse, setBlogResponse] = useState({});
  const [breadCrumb, setBreadCrumb] = useState<Breadcrumb>({
    newsTitle: { title: "", slug: "" },
    newsSubTopic: { title: "", slug: "" },
    newsTopic: { title: "", slug: "" },
    home: { title: "", slug: "" },
    stream: { title: "", slug: "" },
  });
  const [trendingTopic, setTrendingTopic] = useState([]);
  const [relatedBlogs, setRelatedBlogs] = useState([]);
  const [otherNews, setOtherNews] = useState([]);
  const [prevBlog, setPrevBlog] = useState<any>({});
  const [nextBlog, setNextBlog] = useState<any>({});

  //Page loading
  const [loading, setLoading] = useState<boolean>(true);
  const [scrollProgress, setScrollProgress] = useState(0);

  const handleScrollProgress = (progress: any) => {
    setScrollProgress(progress);
  };
  interface HeaderProps {
    titleInfo: any;
  }

  // get Blog Information based on Blog / News Id
  const getBlogById = async () => {
    await axios
      .get(config.apiUrl + `/api/blogs/blogDetails/${titleSlug}`)
      .then((response) => {
        setTrendingTopic(response?.data?.trendingTopics);
        setBlogResponse(response?.data?.blogDetails);
        setBreadCrumb(response?.data?.blogDetails?.breadCrumb || []);
        setRelatedBlogs(response?.data?.blogRelated);
        setOtherNews(response?.data?.otherNews);
        setPrevBlog(response?.data?.previousBlog);
        setNextBlog(response?.data?.nextBlog);

        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        console.log("error", error);
      });
  };
  useEffect(() => {
    setLoading(true);
    getBlogById();
  }, [location]);



  const prevOrNextSlider = useMemo(() => {
    const prevNextSliderContent: any[] = [];
    if (nextBlog?.slug)
      prevNextSliderContent.push(
        <PrevOrNextBlog
          heading="Next Blog"
          title={nextBlog?.title}
          description={nextBlog?.description}
          url={getBlogOrNewsUrl(nextBlog)}
        />
      );
    if (prevBlog?.slug)
      prevNextSliderContent.push(
        <PrevOrNextBlog
          heading="Previous Blog"
          title={prevBlog?.title}
          description={prevBlog?.description}
          url={getBlogOrNewsUrl(prevBlog)}
        />
      );

    return prevNextSliderContent.length > 0 ? (
      <div className="py-12">
        <CustomSwiper
          items={prevNextSliderContent}
          navigation={window.innerWidth < 728 ? "bottom" : "side"}
        />
      </div>
    ) : (
      <></>
    );
  }, [nextBlog?.slug, prevBlog?.slug]);

  if (loading) {
    return <Loader />;
  }

  return (
    <>
     
      <div className="container grid grid-cols-1 gap-4 px-4 py-8 max-w-[1200px] max sm:grid-cols-1 md:grid-cols-1 gap-y-4">
        {/* <div className="visible md:hidden sm:block">Friday, July 26, 2024</div> */}
        <TrendingTopics trendingTopics={trendingTopic} />
        <div className="hidden py-4 md:flex lg:flex">
          <div className="flex justify-center w-full py-4">
            <BlogsBanner
              width=""
              height=""
              image={bannerImage2}
              link="https://sageuniversity.in/"
            />
          </div>
        </div>
        <BlogsBreadcrumb
          newsTitle={breadCrumb.newsTitle || []}
          newsSubTopic={breadCrumb.newsSubTopic || []}
          newsTopic={breadCrumb.newsTopic || []}
          home={breadCrumb.home || []}
          stream={breadCrumb?.stream || []}
        />

        {/* <div className="grid grid-row">
          <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-4 gap-y-4 md:gap-4">
            <div className="grid col-span-3 gap-4 grid-row">
              <Blog />
            </div>
            <div className="p-4 bg-gray-100">Add Section</div>
          </div>
          <div>
            <h1 className="mt-6 mb-6 text-3xl font-semibold">
              More Content...
            </h1>
            <RelatedBlogs />
          </div>
        </div> */}
        {blogResponse ? (
          <>
            {width > 700 ? (
              <div className="grid grid-cols-4 grid-rows gap-x-10">
                <div className="col-span-3 row-span-2">
                  <Blog
                    blogResponse={blogResponse}
                    onScrollProgress={handleScrollProgress}
                    breadCrumb={breadCrumb}
                    // Pass the handler function here
                  />
                  {/* <AboutAuthor authorDetails={blogResponse} /> */}
                  {/* <div className="flex gap-4 mx-auto mt-6 w-fit">
                <PrevButton />
                <NextButton />
              </div> */}

                  {followUsContent}
                  {prevOrNextSlider}
                </div>

                {/* Right side Ad Section */}

                {/* Right side Related Blog Section - Business Requirement */}

                <div className="col-span-1">
                  <a href="https://www.aec.edu.in/" target="_blank">
                    <img
                      src={AdityaVertical}
                      alt="course quiz text"
                      className="mx-auto"
                    />
                  </a>
                  <RelatedBlogs
                    title="Knowledge Hub"
                    blogRelated={relatedBlogs} // Pass the handler function here
                    otherNews={otherNews}
                  />
                  <div className="col-span-1">
                    <a href="https://www.amrita.edu/" target="_blank">
                      <img
                        src={AmritaVertical}
                        alt="Advertisement"
                        className="mx-auto"
                      />
                    </a>
                  </div>
                </div>

                {/* Bottom Related Blogs Section`` */}

                {/* <div className="col-span-3">
              <h1 className="mt-6 mb-6 text-3xl font-semibold">
                More Content...
              </h1>
              <RelatedBlogs blogRelated={relatedBlogs} />
            </div> */}
              </div>
            ) : (
              <div className="flex flex-col w-full">
                <div className="w-full">
                  <Blog
                    blogResponse={blogResponse}
                    onScrollProgress={handleScrollProgress}
                    breadCrumb={breadCrumb}
                  />
                  {/* <AboutAuthor authorDetails={blogResponse} /> */}
                  {/* <div className="flex gap-4 mx-auto my-6 w-fit">
                <PrevButton />
                <NextButton />
              </div> */}
                  {followUsContent}
                  {prevOrNextSlider}
                </div>
              </div>
            )}
          </>
        ) : (
          <h1>No Resuls Found!</h1>
        )}
      </div>
      {width < 700 && (
        <div className="pb-4">
          <RelatedBlogs
            title="Knowledge Hub"
            blogRelated={relatedBlogs}
            otherNews={otherNews}
          />
        </div>
      )}
    </>
  );
};

export default BlogDetails;

// Right Arrow SVG
const RightArrow = () => {
  return (
    <div className={`p-4 border rounded-full ${"bg-blue border-blue"}`}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className={`${"fill-current text-white"}`}
          d="M1.20833 5.45842H11.625L8.19792 2.03134C8.10028 1.9345 8.02279 1.8193 7.9699 1.69236C7.91702 1.56542 7.88979 1.42927 7.88979 1.29176C7.88979 1.15425 7.91702 1.01809 7.9699 0.891158C8.02279 0.764222 8.10028 0.649012 8.19792 0.552176C8.39309 0.358165 8.6571 0.249268 8.93229 0.249268C9.20748 0.249268 9.4715 0.358165 9.66667 0.552176L14.1354 5.03134C14.5267 5.42024 14.7477 5.94844 14.75 6.50008V6.50008C14.7449 7.04811 14.5241 7.57207 14.1354 7.95842L9.66667 12.4376C9.56954 12.534 9.45438 12.6104 9.32774 12.6623C9.2011 12.7142 9.06548 12.7407 8.92861 12.7402C8.79174 12.7398 8.6563 12.7123 8.53004 12.6595C8.40377 12.6067 8.28915 12.5295 8.19271 12.4324C8.09627 12.3352 8.0199 12.2201 7.96797 12.0934C7.91604 11.9668 7.88956 11.8312 7.89005 11.6943C7.89053 11.5574 7.91797 11.422 7.97079 11.2957C8.02362 11.1695 8.10079 11.0549 8.19792 10.9584L11.625 7.54175H1.20833C0.932065 7.54175 0.667112 7.432 0.471762 7.23665C0.276411 7.0413 0.166666 6.77635 0.166666 6.50008C0.166666 6.22382 0.276411 5.95887 0.471762 5.76352C0.667112 5.56817 0.932065 5.45842 1.20833 5.45842Z"
        />
      </svg>
    </div>
  );
};

//Left Arrow SVG
const LeftArrow = () => {
  return (
    <div className="p-4 bg-white border border-black rounded-full hover:border-blue hover:bg-blue group">
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          className="text-black fill-current group-hover:text-white"
          d="M13.7917 5.45842H3.375L6.80208 2.03134C6.89972 1.9345 6.97721 1.8193 7.0301 1.69236C7.08298 1.56542 7.11021 1.42927 7.11021 1.29176C7.11021 1.15425 7.08298 1.01809 7.0301 0.891158C6.97721 0.764222 6.89972 0.649012 6.80208 0.552176C6.60691 0.358165 6.3429 0.249268 6.06771 0.249268C5.79252 0.249268 5.5285 0.358165 5.33333 0.552176L0.864584 5.03134C0.473346 5.42024 0.252326 5.94844 0.25 6.50008V6.50008C0.255069 7.04811 0.47588 7.57207 0.864584 7.95842L5.33333 12.4376C5.43046 12.534 5.54562 12.6104 5.67226 12.6623C5.7989 12.7142 5.93452 12.7407 6.07139 12.7402C6.20826 12.7398 6.3437 12.7123 6.46996 12.6595C6.59623 12.6067 6.71085 12.5295 6.80729 12.4324C6.90373 12.3352 6.9801 12.2201 7.03203 12.0934C7.08396 11.9668 7.11044 11.8312 7.10995 11.6943C7.10947 11.5574 7.08203 11.422 7.02921 11.2957C6.97638 11.1695 6.89921 11.0549 6.80208 10.9584L3.375 7.54175H13.7917C14.0679 7.54175 14.3329 7.432 14.5282 7.23665C14.7236 7.0413 14.8333 6.77635 14.8333 6.50008C14.8333 6.22382 14.7236 5.95887 14.5282 5.76352C14.3329 5.56817 14.0679 5.45842 13.7917 5.45842Z"
        />
      </svg>
    </div>
  );
};
