// FlightAnimation.js
import React, { useRef, useEffect, useState } from "react";
import planeImage from "../../../../../../images/authentication/plane-svg.svg";
import planeRotated from "../../../../../../images/authentication/plane-rotated.svg";
import planeNew from "../../../../../../images/authentication/plane-new.svg";
import planeRotatedRepo from "../../../../../../images/authentication/plane-svgrepo-com.svg";
import TakeOffplane from "../../../../../../images/authentication/takeoff-the-plane-svg.svg";

interface FlightAnimationProps {
  step: number;
  steps: number;
}

const FlightAnimation: React.FC<FlightAnimationProps> = ({ step, steps }) => {
  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageLoaded, setImageLoaded] = useState(false);
  const plane = useRef(new Image());

  const previousStepRef = useRef<number>(step);
  const animationRef = useRef<number | null>(null);

  useEffect(() => {
    // Set up the plane image
    plane.current.src = TakeOffplane;
    plane.current.onload = () => setImageLoaded(true);

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, []);

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas || !imageLoaded) return;

    const ctx = canvas.getContext("2d");
    if (!ctx) return;

    // Canvas dimensions
    const canvasWidth = canvas.width;
    const canvasHeight = canvas.height;
    const planeSize = 30;

    // Curve control points
    const startX = 20;
    const startY = canvasHeight - 10;
    const endX = canvasWidth - 20;
    const endY = startY;
    const controlX = canvasWidth / 2;
    const controlY = -20; // Adjusted control point for more pronounced curve

    // Function to draw the plane and path
    const drawPathAndPlane = (progress: number) => {
      // Clear canvas
      ctx.clearRect(0, 0, canvas.width, canvas.height);

      // Draw the dashed curve path
      ctx.setLineDash([10, 10]); // Dashed line (---)
      ctx.beginPath();
      ctx.moveTo(startX, startY);
      ctx.quadraticCurveTo(controlX, controlY, endX, endY);
      ctx.strokeStyle = "#ffffff"; // White color for the path
      ctx.lineWidth = 2;
      ctx.stroke();
      ctx.setLineDash([]);

      // Draw dots along the curve
      for (let i = 0; i < steps; i++) {
        const dotProgress = i / (steps - 1);
        const dotX = (1 - dotProgress) * (1 - dotProgress) * startX + 2 * (1 - dotProgress) * dotProgress * controlX + dotProgress * dotProgress * endX;
        const dotY = (1 - dotProgress) * (1 - dotProgress) * startY + 2 * (1 - dotProgress) * dotProgress * controlY + dotProgress * dotProgress * endY;
        ctx.fillStyle = i <= step ? "#ffffff" : "#444444";  // White for completed dots, grey for upcoming dots
        ctx.beginPath();
        ctx.arc(dotX, dotY, 5, 0, Math.PI * 2);
        ctx.fill();
      }

      // Calculate the flight's position
      const startT = previousStepRef.current / (steps - 1);
      const endT = step / (steps - 1);
      const currentT = startT + (endT - startT) * progress;

      const xPosition = (1 - currentT) * (1 - currentT) * startX + 2 * (1 - currentT) * currentT * controlX + currentT * currentT * endX;
      const yPosition = (1 - currentT) * (1 - currentT) * startY + 2 * (1 - currentT) * currentT * controlY + currentT * currentT * endY;

      // Calculate angle for rotation along the curve
      const deltaX = 2 * (1 - currentT) * (controlX - startX) + 2 * currentT * (endX - controlX);
      const deltaY = 2 * (1 - currentT) * (controlY - startY) + 2 * currentT * (endY - controlY);
      const angle = Math.atan2(deltaY, deltaX);

      // Draw and rotate the plane image
      ctx.save();
      ctx.translate(xPosition, yPosition);
      ctx.rotate(angle);
      ctx.drawImage(plane.current, -planeSize / 2, -planeSize / 2, planeSize, planeSize);
      ctx.restore();
    };

    // Animation loop for smooth transition
    const animateTransition = (startTimestamp: number) => {
      const duration = 500; // Duration of the animation in milliseconds
      const timestamp = performance.now();
      const progress = Math.min((timestamp - startTimestamp) / duration, 1);

      drawPathAndPlane(progress);

      if (progress < 1) {
        animationRef.current = requestAnimationFrame(() => animateTransition(startTimestamp));
      } else {
        previousStepRef.current = step; // Update previous step after animation completes
      }
    };

    // Trigger animation only after the image is loaded and the step is changed
    if (imageLoaded) {
      if (previousStepRef.current !== step) {
        // Start animation when the step changes
        if (animationRef.current) {
          cancelAnimationFrame(animationRef.current);
        }
        animationRef.current = requestAnimationFrame((timestamp) => animateTransition(timestamp));
      } else {
        // Ensure the flight is visible in the correct initial position for step 1
        drawPathAndPlane(1);
      }
    }

    return () => {
      if (animationRef.current) {
        cancelAnimationFrame(animationRef.current);
      }
    };
  }, [step, steps, imageLoaded]);

  return (
    <canvas
      ref={canvasRef}
      width="314"
      height="67"
      style={{
        verticalAlign: "top",
        width: "314px",
        height: "67px",
        // border: "1px solid #ddd",
        marginBottom: "20px"
      }}
    />
  );
};

export default FlightAnimation;
