import { useDispatch, useSelector } from "react-redux";
import ProfileDetailCard from "./ProfileDetailCard";
import { AppDispatch, RootState } from "../../../store/store";
import { useEffect } from "react";
import { getMyProfile, getOptionData } from "./myProfileThunk";
import { FormInput } from "./FieldInput";
import PreferenceEditor from "./PreferenceEditor";

const MyProfile = () => {
  const dispatch = useDispatch<AppDispatch>();

  const optionData = useSelector(
    (state: RootState) => state.myProfile.optionData
  );

  const PROFILE_DETAIL_SCHEMA = [
    {
      name: "Basic Details",
      subCategory: [
        {
          fields: [
            {
              key: "firstName",
              name: "First Name",
              type: FormInput.Text,
              required: true,
            },
            {
              key: "lastName",
              name: "Last Name",
              type: FormInput.Text,
              required: true,
            },
            {
              key: "email",
              name: "Email",
              type: FormInput.Text,
              required: true,
              validationRegex: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
            },
            {
              key: "mobileNumber",
              name: "Mobile number",
              type: FormInput.Text,
              required: true,
              validationRegex: /^[0-9]{10}$/,
            },
          ],
        },
      ],
    },
    {
      name: "Contact details",
      subCategory: [
        {
          fields: [
            {
              key: "city",
              name: "City",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.cities?.map(
                (item: { cityId: number; cityName: string }) => ({
                  key: item.cityId.toString(),
                  name: item.cityName,
                })
              ),
            },
            {
              key: "state",
              name: "State",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.states?.map(
                (item: { stateId: number; stateName: string }) => ({
                  key: item.stateId.toString(),
                  name: item.stateName,
                })
              ),
            },
          ],
        },
      ],
    },
    {
      name: "Educational Preferences",
      subCategory: [
        {
          fields: [
            {
              key: "currentLevelOfStudy",
              name: "Level",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.currentLevelOfStudy?.map((item: string) => ({
                key: item,
                name: item,
              })),
            },
            {
              key: "lookingForDegree",
              name: "Degree",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.lookingForDegree?.map((item: string) => ({
                key: item,
                name: item,
              })),
            },
            {
              key: "lookingForStream",
              name: "Stream",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.lookingForStream?.map(
                (item: { streamId: number; streamName: string }) => ({
                  key: item.streamId.toString(),
                  name: item.streamName,
                })
              ),
            },
            {
              key: "lookingForCourse",
              name: "Course",
              type: FormInput.Dropdown,
              required: true,
              options: optionData?.lookingForCourse?.map(
                (item: { courseId: number; courseName: string }) => ({
                  key: item.courseId.toString(),
                  name: item.courseName,
                })
              ),
            },
          ],
        },
      ],
    },
    // {
    //   name: "Exam details",
    //   subCategory: [
    //     {
    //       repeatedFieldGroupKey: "userExamDetails",
    //       fields: [
    //         {
    //           key: "examId",
    //           name: "Exam",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "0", name: "JEE" },
    //             { key: "1", name: "NEET" },
    //           ],
    //         },
    //         {
    //           key: "examStatus",
    //           name: "Status",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "appeared", name: "Appeared" },
    //             { key: "preparing", name: "Preparing" },
    //           ],
    //         },
    //         {
    //           key: "examResultStatus",
    //           name: "Result status",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "declared", name: "Declared" },
    //             { key: "awaiting", name: "Awaiting" },
    //           ],
    //         },
    //         {
    //           key: "appreadIn",
    //           name: "Appeared In",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "2001", name: "2001" },
    //             { key: "2002", name: "2002" },
    //             { key: "2003", name: "2003" },
    //             { key: "2004", name: "2004" },
    //             { key: "2005", name: "2005" },
    //             { key: "2006", name: "2006" },
    //             { key: "2007", name: "2007" },
    //             { key: "2008", name: "2008" },
    //             { key: "2009", name: "2009" },
    //             { key: "2010", name: "2010" },
    //           ],
    //         },
    //         {
    //           key: "rankMarksPercentage",
    //           name: "Percentage",
    //           type: FormInput.Text,
    //           required: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
    // {
    //   name: "Education details",
    //   subCategory: [
    //     {
    //       repeatedFieldGroupKey: "userEducationDetails",
    //       fields: [
    //         {
    //           key: "educationType",
    //           name: "Education Type",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "ug", name: "UG" },
    //             { key: "pg", name: "PG" },
    //           ],
    //         },
    //         {
    //           key: "schoolCollege",
    //           name: "Institute",
    //           type: FormInput.Text,
    //           required: true,
    //         },
    //         {
    //           key: "board",
    //           name: "Board",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "cbse", name: "CBSE" },
    //             { key: "icse", name: "icse" },
    //           ],
    //         },
    //         {
    //           key: "passingYear",
    //           name: "Passing Year",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "2001", name: "2001" },
    //             { key: "2002", name: "2002" },
    //             { key: "2003", name: "2003" },
    //             { key: "2004", name: "2004" },
    //             { key: "2005", name: "2005" },
    //             { key: "2006", name: "2006" },
    //             { key: "2007", name: "2007" },
    //             { key: "2008", name: "2008" },
    //             { key: "2009", name: "2009" },
    //             { key: "2010", name: "2010" },
    //           ],
    //         },
    //         {
    //           key: "streamId",
    //           name: "Stream",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: optionData?.lookingForStream?.map(
    //             (item: { streamId: number; streamName: string }) => ({
    //               key: item.streamId.toString(),
    //               name: item.streamName,
    //             })
    //           ),
    //         },
    //         {
    //           key: "marksType",
    //           name: "CGPA Type",
    //           type: FormInput.Dropdown,
    //           required: true,
    //           options: [
    //             { key: "percentage", name: "Percentage" },
    //             { key: "cgpa-5", name: "CGPA out of 5" },
    //             { key: "cgpa-10", name: "CGPA out of 10" },
    //           ],
    //         },
    //         {
    //           key: "percentage_cgpa",
    //           name: "CGPA",
    //           type: FormInput.Text,
    //           required: true,
    //         },
    //       ],
    //     },
    //   ],
    // },
  ];

  useEffect(() => {
    dispatch(getMyProfile());
    dispatch(getOptionData());
  }, []);

  return (
    <div className="flex flex-col gap-4 p-4 lg:gap-8 lg:p-8">
      {PROFILE_DETAIL_SCHEMA.map((item) => (
        <ProfileDetailCard {...item} />
      ))}
      <PreferenceEditor />
    </div>
  );
};

export default MyProfile;
