import React from "react";
import { useSelector } from "react-redux";
import { RootState } from "../store/store";
import { Navigate, Outlet, RouterProps, useLocation } from "react-router-dom";

const ProtectedRoute = () => {
  const location = useLocation();

  // const isAuthenticated = localStorage.getItem("user") != null;

  // use it for local dev purpose incase login api fails
  const isAuthenticated = true;

  return isAuthenticated ? (
    <Outlet />
  ) : (
    <Navigate to="/secure/login" state={{ from: location }} replace />
  );
};

export default ProtectedRoute;
