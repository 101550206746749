import classNames from "classnames";
import React, { useState } from "react";

interface Reward {
  rank: string;
  options: string[];
}

interface CardContent {
  title: string;
  description: string;
  additionalDescription: string;
  secondaryTitle: string;
  rewards: Reward[];
}

interface CardData {
  id: number;
  title: string;
  content: CardContent;
}

const cardsData: CardData[] = [
  {
    id: 1,
    title: "Merit Category Rewards",
    content: {
      title: "Merit Category Rewards. Ratan Tata Scholarship Awards",
      description:
        "The Merit Category rewards outstanding students with exciting opportunities, allowing them to choose one reward: a World Education Tour, significant tuition discounts at top institutes, or a cash prize.",
      additionalDescription:
        "This initiative is a fantastic way to recognize academic excellence and support students in achieving their dreams!",
      secondaryTitle: "",
      rewards: [
        {
          rank: "Ranks 1 - 10",
          options: [
            "World Education Tour to Europe (7 Days)",
            "25% off first-year tuition fees at prestigious institutes (IIT, NIT, AIIMS, NIFT, NID)",
            "Cash Prize of ₹ 75,000",
          ],
        },
        { rank: "Ranks 11 - 15", options: ["Cash Prize of ₹ 50,000"] },
        { rank: "Ranks 16 - 20", options: ["Cash Prize of ₹ 25,000"] },
      ],
    },
  },
  {
    id: 2,
    title: "Championing Gender Equality",
    content: {
      title: "Championing Gender Equality!",
      description:
        "Championing Gender Equality means ensuring everyone has equal rights and opportunities. By fostering an inclusive environment, we empower individuals to thrive and create a brighter, more equitable future for all",
      additionalDescription:
        "We’re excited to award a total of 1000 scholarships—500 for boys and 500 for girls - selected from the merit list.6",
      secondaryTitle: " Champion Awards Breakdown for Boys & Girls",
      rewards: [
        {
          rank: "Ranks 1 - 25",
          options: ["Cash Prize of ₹ 1,00,000"],
        },
        { rank: "Ranks 26 - 300", options: ["Cash Prize of ₹ 50,000"] },
        { rank: "Ranks 301 - 500", options: ["Cash Prize of ₹ 25,000"] },
      ],
    },
  },
  {
    id: 3,
    title: "Government School Students",
    content: {
      title: "Government School Students!",
      description:
        "The Merit Category rewards outstanding students with exciting opportunities, allowing them to choose one reward: a World Education Tour, significant tuition discounts at top institutes, or a cash prize.",
      additionalDescription:
        "This initiative is a fantastic way to recognize academic excellence and support students in achieving their dreams!",
      secondaryTitle: "All-Star Awards Breakdown",
      rewards: [
        {
          rank: "Ranks 1 - 15",
          options: ["Cash Prize of ₹ 10,000"],
        },
        {
          rank: "Ranks 16 - 60",
          options: ["₹10,000 scholarship at partner universities"],
        },
        {
          rank: "Ranks 61 - 100",
          options: ["College Mentor services worth ₹7,500"],
        },
      ],
    },
  },
  {
    id: 4,
    title: "Empowering LGBTQ+ Students!",
    content: {
      title: "Empowering LGBTQ+ Students!",
      description:
        "Empowering LGBTQ+ students means creating a supportive environment where they can express their identities freely and pursue their dreams. By championing inclusivity and acceptance, we help foster confidence and resilience for a brighter future.",
      additionalDescription:
        "Committed to supporting and celebrating the achievements of LGBTQ+ students with scholarships for rankers 1-100.",
      secondaryTitle: "Diversity Excellence Awards Breakdown",
      rewards: [
        {
          rank: "Ranks 1 - 15",
          options: ["Cash Prize of ₹ 10,000"],
        },
        {
          rank: "Ranks 16 - 60",
          options: ["₹10,000 scholarship at partner universities"],
        },
        {
          rank: "Ranks 61 - 100",
          options: ["College Mentor services worth ₹7,500"],
        },
      ],
    },
  },
  {
    id: 5,
    title: "Students with Disabilities",
    content: {
      title: "Students with Disabilities!",
      description:
        "Supporting students with disabilities involves providing the necessary resources and opportunities to ensure they can thrive in their education. By promoting inclusivity and understanding, we empower these students to achieve their full potential and lead fulfilling lives",
      additionalDescription:
        "Recognizing the achievements and potential of students with disabilities with scholarships for rankers 1-100.",
      secondaryTitle: "Scholarship Rewards for a Brighter Future",
      rewards: [
        {
          rank: "Ranks 1 - 15",
          options: ["Cash Prize of ₹ 10,000"],
        },
        {
          rank: "Ranks 16 - 60",
          options: ["₹10,000 scholarship at partner universities"],
        },
        {
          rank: "Ranks 61 - 100",
          options: ["College Mentor services worth ₹7,500"],
        },
      ],
    },
  },

  {
    id: 6,
    title: "Cultural Exchange Programs",
    content: {
      title: "Merit Category Rewards. Ratan Tata Scholarship Awards",
      description:
        "The Merit Category rewards outstanding students with exciting opportunities, allowing them to choose one reward: a World Education Tour, significant tuition discounts at top institutes, or a cash prize.",
      additionalDescription:
        "This initiative is a fantastic way to recognize academic excellence and support students in achieving their dreams!",
      secondaryTitle: "Scholarship Rewards for a Brighter Future",
      rewards: [
        {
          rank: "Ranks 1 - 3",
          options: [
            "Mentorship with Industry Experts",
            "Scholarship for STEM Courses",
            "Cash Prize of ₹ 1,00,000",
          ],
        },
        { rank: "Ranks 4 - 10", options: ["Cash Prize of ₹ 25,000"] },
        { rank: "Ranks 4 - 10", options: ["Cash Prize of ₹ 25,000"] },
      ],
    },
  },
];

const ScholorshipCategoryAward = () => {
  const [expandedCardId, setExpandedCardId] = useState<number>(cardsData[1].id);

  const handleCardClick = (id: number) => {
    if (id !== expandedCardId) {
      setExpandedCardId(id);
    }
  };

  return (
    <div className="scholarship-category-award-section bg-[#173cba]">
      <div className="mx-auto">
        <div className="bg-[#fff] pb-8 px-4">
          <h3 className="text-center text-[#173CBA] font-light text-3xl md:text-[48px]">
            Scholarship Category Awards Overview
          </h3>
          <p className="text-center text-[#173cba] font-bold text-3xl md:text-[48px]">
            Total Scholarships Worth: ₹1 Crore <br />
            <span className="text-black text-xl md:text-[36px] font-light">
              Merit list will be created based on marks from the scholarship
              test.
            </span>
          </p>
        </div>

        <div className="flex flex-col md:flex-row w-full md:container h-full md:h-[563px]">
          {cardsData.map((card, index) => (
            <React.Fragment key={card.id}>
              <div
                onClick={() => handleCardClick(card.id)}
                className={classNames(
                  "relative cursor-pointer transform transition-all duration-500 ease-in-out overflow-hidden",
                  expandedCardId === card.id
                    ? "h-auto w-full md:w-full"
                    : "h-[100px] w-full md:h-[563px] md:w-[111px]"
                )}
              >
                <div className="bg-[#173cba] text-white h-full flex flex-col relative">
                  <div
                    className={classNames(
                      "p-4 flex items-center justify-center",
                      expandedCardId === card.id
                        ? "absolute top-0 left-0 w-full h-full"
                        : "h-full"
                    )}
                  >
                    {expandedCardId !== card.id && (
                      <h2 className="text-[24px] md:text-[30px] font-light md:-rotate-90 whitespace-nowrap">
                        {card.title}
                      </h2>
                    )}
                  </div>

                  {expandedCardId === card.id && (
                    <div className="my-auto px-8 leading-7 space-y-4 max-md:py-8">
                      <h3 className="font-light text-[20px] md:text-[30px] text-[#FFD540] mb-2 md:p-1">
                        {card.content.title}
                      </h3>
                      <p className="text-sm md:text-base font-normal md:p-1">
                        {card.content.description}
                      </p>
                      <p className="text-sm md:text-base font-normal md:p-1">
                        {card.content.additionalDescription}
                      </p>
                      <p className="text-sm md:text-[24px] font-semibold md:p-1">
                        {card.content.secondaryTitle}
                      </p>
                      <div className="space-y-4 mt-4">
                        {card.content.rewards.map((reward, idx) => (
                          <div key={idx}>
                            <h4 className="font-semibold text-[24px] mb-2">
                              {reward.rank}:
                            </h4>
                            <ul className="list-disc ml-4 text-[#FFD540] text-base">
                              {reward.options.map((option, optionIdx) => (
                                <li key={optionIdx}>{option}</li>
                              ))}
                            </ul>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>

              {index < cardsData.length - 1 && (
                <>
                  <div className="block md:hidden h-[2px] bg-[#0c9] w-full my-2"></div>

                  <div className="hidden md:block w-[2px] bg-[#0c9] h-[563px]"></div>
                </>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ScholorshipCategoryAward;
