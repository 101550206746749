import React, { useState, ChangeEvent, useRef, FormEvent } from "react";
import Modal from "../../components/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faChevronRight,
  faChevronLeft,
} from "@fortawesome/free-solid-svg-icons";
import "./Careers.scss";
import RevealFromBottom from "./assets/RevealFromBottom";
import Reveal from "react-awesome-reveal";
import { customAnimationBottom } from "./assets/keyframes";
import HeroSection, { HeroSectionProps } from "./assets/HeroSection";
import HeroTextHighlight from "./assets/hero-text-highlight.svg";
import YourLifeTextHighlight from "./assets/your-life-heading-highlight.svg";
import whatteamsays from "./assets/what-team-says.svg";
import TextHighlight from "./assets/our-investors-heading-text-highlight.svg";
import TextHighlight1 from "./assets/sketch-text-highlight.svg";

import JobBoard from "./JobBoard";
export type Job = {
  title: string;
  minExp: number;
  maxExp: number;
  isFullTime: boolean;
  location: string;
  tags: string[];
  description: string;
  responsibilities: string[];
  requirements: string[];
  companyName?: string;
  benefits: string[];
  aboutCompany: string;
  jobType?: string;
};
const jobData: Job[] = [
  {
    title: "Content Writer",
    minExp: 1,
    maxExp: 4,
    companyName: "Dreams and Degrees EdTech Private Limited",
    isFullTime: true,
    location: "Madhapur, Hyderabad, Telangana",
    tags: ["Urgently Hiring"],
    aboutCompany:
      "Dreams and Degrees is a premier Career guidance and Mentoring platform for students pursuing Engineering, Management, Architecture, and various other courses. Our platform offers personalized mentorship, exam preparation, university profiles, career counselling, and more to help students make informed decisions about their educational and professional journey.",
    description:
      "We are seeking a passionate and knowledgeable Career Counsellor to join our team, dedicated to guiding students towards making informed decisions about their academic and career paths. The ideal candidate will have a background in Psychology (B.A, M.A) with a strong understanding of adolescent development and career planning strategies. ",
    responsibilities: [
      "Career Counselling Sessions: Conduct individual and group counselling sessions with students to assess their interests, skills, and strengths, guiding them towards suitable career choices.",
      "Psychometric Assessments: Administer and interpret psychometric assessments to evaluate students' aptitudes and preferences, providing personalized career recommendations.",
      "Educational Pathways: Advise students on suitable educational pathways based on their career aspirations, including subject choices, streams (Science, Commerce, Humanities), and competitive examinations.",
      "College and Course Guidance: Assist students in researching and selecting colleges, universities, and courses that align with their career goals, considering academic performance, financial feasibility, and personal preferences.",
      "Career Awareness Programs: Organize workshops, seminars, and career fairs to increase student`s awareness of various career options, industry trends, and skill requirements.",
      "Parent Counselling: Collaborate with parents to provide insights into their child's career interests and academic progress, fostering a supportive environment for career decision-making.",
      "Skill Development: Recommend skill enhancement programs, internships, and extracurricular activities to students to bolster their academic profile and prepare them for future careers.",
      "Documentation and Reporting: Maintain accurate records of counseling sessions, assessments, and career plans. Generate reports to track students' progress and outcomes of counseling interventions.",
    ],
    requirements: [
      "Bachelor's and Master's degree in Psychology is must.",
      "Proven experience in career counseling, preferably in an educational domain.",
      "Strong understanding of adolescent psychology and career development theories.",
      "Excellent communication and interpersonal skills, with the ability to engage effectively with students and parents.",
      "Knowledge of psychometric tools and career assessment instruments etc.",
      "Ability to work independently and as part of a collaborative team.",
      "Familiarity with the Indian education system, including board examinations, college admissions processes, and career options.",
      "Certification in Career Counseling is added advantage.",
      "Experience in conducting workshops, seminars, or career awareness programs.",
      "Understanding of current trends in higher education and job markets.",
    ],
    benefits: [
      "Competitive salary and benefits package.",
      "Opportunity to work with a dynamic and supportive team.",
      "Professional development and growth opportunities.",
    ],
  },
  {
    title: "UX/UI Designer",
    minExp: 1,
    maxExp: 4,
    companyName: "Dreams and Degrees EdTech Private Limited",
    jobType: "On-site",
    isFullTime: true,
    location: "Madhapur, Hyderabad, Telangana",
    tags: ["Urgently Hiring"],
    aboutCompany:
      "Dreams and Degrees is a premier Career guidance and Mentoring platform for students pursuing Engineering, Management, Architecture, and various other courses. Our platform offers personalized mentorship, exam preparation, university profiles, career counselling, and more to help students make informed decisions about their educational and professional journey",
    description:
      "We are seeking a talented and experienced UX/UI Designer to join our team and contribute to the development of our website and mobile application. As a UX/UI Designer at Dreams and Degrees, you will play a crucial role in creating intuitive and visually appealing user interfaces that enhance the overall user experience. You will collaborate with cross-functional teams including developers, marketers, and content creators to ensure our platform meets the needs of our users and reflects our brand identity.",
    responsibilities: [
      "Design and create user-centric, responsive interfaces for our website and mobile application",
      "Collaborate with product managers and developers to understand user requirements and translate them into design solutions",
      "Conduct user research, usability testing, and gather feedback to continually improve the user experience",
      "Create wireframes, prototypes, and design mock-ups to communicate design concepts effectively",
      "Ensure consistency in design elements and adherence to brand guidelines across all platforms",
      "Stay updated with industry trends, best practices, and emerging technologies in UX/UI design",
    ],
    requirements: [
      "Bachelor's degree in Design, Human-Computer Interaction, or related field ",
      "Proven experience as a UX / UI Designer with a strong portfolio showcasing your design skills and projects",
      "Proficiency in design tools such as Adobe XD, Sketch, Figma, and similar tools",
      "Knowledge of user research methodologies, usability principles, and information architecture",
      "Strong communication and collaboration skills to work effectively in a cross - functional team environment",
      "Experience with AI/ML-driven recommendation engines and data-driven design is a plus",
    ],
    benefits: [
      "Competitive salary and benefits package",
      "Opportunity to work in a dynamic and innovative start-up environment",
      "Career growth and professional development opportunities",
      "Collaborative and supportive team culture",
      "If you are passionate about creating impactful user experiences and want to be part of a missiondriven company that empowers students in their educational journey, we'd love to hear from you!",
    ],
  },
  {
    title: "Career Counsellor",
    minExp: 1,
    maxExp: 4,
    companyName: "Dreams and Degrees EdTech Private Limited",
    isFullTime: true,
    location: "Madhapur, Hyderabad, Telangana",
    tags: ["Urgently Hiring"],
    aboutCompany:
      "Dreams and Degrees is a premier Career guidance and Mentoring platform for students pursuing Engineering, Management, Architecture, and various other courses. Our platform offers personalized mentorship, exam preparation, university profiles, career counselling, and more to help students make informed decisions about their educational and professional journey.",
    description:
      "We are seeking a passionate and knowledgeable Career Counsellor to join our team, dedicated to guiding students towards making informed decisions about their academic and career paths. The ideal candidate will have a background in Psychology (B.A, M.A) with a strong understanding of adolescent development and career planning strategies.",
    responsibilities: [
      "Career Counselling Sessions: Conduct individual and group counselling sessions with students to assess their interests, skills, and strengths, guiding them towards suitable career choices.",
      "Psychometric Assessments: Administer and interpret psychometric assessments to evaluate students' aptitudes and preferences, providing personalized career recommendations.",
      "Educational Pathways: Advise students on suitable educational pathways based on their career aspirations, including subject choices, streams (Science, Commerce, Humanities), and competitive examinations.",
      "College and Course Guidance: Assist students in researching and selecting colleges, universities, and courses that align with their career goals, considering academic performance, financial feasibility, and personal preferences.",
      "Career Awareness Programs: Organize workshops, seminars, and career fairs to increase students' awareness of various career options, industry trends, and skill requirements.",
      "Parent Counselling: Collaborate with parents to provide insights into their child's career interests and academic progress, fostering a supportive environment for career decision-making.",
      "Skill Development: Recommend skill enhancement programs, internships, and extracurricular activities to students to bolster their academic profile and prepare them for future careers.",
      "Documentation and Reporting: Maintain accurate records of counseling sessions, assessments, and career plans. Generate reports to track students' progress and outcomes of counseling interventions.",
    ],
    requirements: [
      "Bachelor's and Master's degree in Psychology is must.",
      "Proven experience in career counseling, preferably in an educational domain.",
      "Strong understanding of adolescent psychology and career development theories.",
      "Excellent communication and interpersonal skills, with the ability to engage effectively with students and parents.",
      "Knowledge of psychometric tools and career assessment instruments etc.",
      "Ability to work independently and as part of a collaborative team.",
      "Familiarity with the Indian education system, including board examinations, college admissions processes, and career options.",
      "Certification in Career Counseling is added advantage.",
      "Experience in conducting workshops, seminars, or career awareness programs.",
      "Understanding of current trends in higher education and job markets.",
    ],
    benefits: [
      "Competitive salary and benefits package.",
      "Opportunity to work with a dynamic and supportive team.",
      "Professional development and growth opportunities.",
    ],
  },
 
];

const NextArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "blue", fontSize: "1.8rem" }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronRight} />
    </div>
  );
};

const PrevArrow = (props: any) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", color: "blue", fontSize: "1.8rem" }}
      onClick={onClick}
    >
      <FontAwesomeIcon icon={faChevronLeft} />
    </div>
  );
};

const carouselSettings = {
  dots: true,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  arrows: false, // Enable arrows
  nextArrow: <NextArrow />,
  prevArrow: <PrevArrow />,
  autoplay: true,
  autoplaySpeed: 3000,
};

const heroSectionData: HeroSectionProps = {
  headings: [
    <>
      Join our{" "}
      <div className="relative inline-block">
        <div className="relative z-10 my-2 text-primary">Team</div>
        <img
          src={HeroTextHighlight}
          className="absolute top-1/2 -translate-y-1/2 left-0 z-0"
        />
      </div>
    </>,
    <>
      at <span className="text-[#003FBC]">Dreams and Degrees</span>
    </>,
  ],
  paragraphs: [
    <>
      Dreams and degrees is India's largest career guidance platform. We provide
      cutting edge ed-tech solutions to parents, students, and counsellors.
      Great Technology. Experienced Colleagues. Dynamic work environment.
    </>,
  ],
  image: "/images/career/join-our-team.jpg",
  linkText: "See open positions",
  anchorLink: "#job-list",
};

const MyForm: React.FC = () => {
  const [isSubmitted, setIsSubmitted] = React.useState(false);
  const formRef = useRef<HTMLFormElement>(null);

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    queries: "",
  });

  const [errors, setErrors] = useState({
    firstName: "",
    lastName: "",
    email: "",
    queries: "",
  });

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    setErrors({
      ...errors,
      [name]: "", // Clear error message when user starts typing
    });
  };

  const validateForm = () => {
    const newErrors = {
      firstName: "",
      lastName: "",
      email: "",
      queries: "",
    };
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.email) newErrors.email = "Email is required";
    else if (!/\S+@\S+\.\S+/.test(formData.email))
      newErrors.email = "Email is invalid";

    setErrors(newErrors);
    return Object.keys(newErrors).every(
      (key) => newErrors[key as keyof typeof newErrors] === ""
    );
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (!validateForm()) return;

    const mailtoLink = `mailto:admin@dreamsanddegrees.com?subject=Career page: Contact Form Submission&body=
        First Name: ${formData.firstName}%0D%0A
        Last Name: ${formData.lastName}%0D%0A
        Email: ${formData.email}%0D%0A
        Queries: ${formData.queries}`;
    window.location.href = mailtoLink;
  };

  return (
    <form className="w-full space-y-6" onSubmit={handleSubmit} ref={formRef}>
      <div className="flex flex-col md:flex-row gap-7">
        <div className="flex flex-col gap-3 w-full md:w-1/2">
          <label
            htmlFor="firstName"
            className="block text-inter-regular-14"
            style={{ fontSize: "16px" }}
          >
            First name<span className="text-[#ff0000] text-sm">*</span>
          </label>
          <input
            type="text"
            id="firstName"
            name="firstName"
            value={formData.firstName}
            onChange={(e) => {
              setFormData({ ...formData, firstName: e.target.value });
              setErrors({ ...errors, firstName: "" });
            }}
            className={`focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm text-black text-base border rounded-lg py-1 px-1 ${
              errors.firstName ? "border-[#ff0000]" : ""
            }`}
            placeholder="First Name"
            style={{ fontSize: "14px" }}
          />
          {/* {errors.firstName && <p className="text-[#ff0000] text-sm">{errors.firstName}</p>} */}
        </div>
        <div className="flex flex-col gap-3 w-full md:w-1/2">
          <label
            htmlFor="lastName"
            className="block text-inter-regular-14"
            style={{ fontSize: "16px" }}
          >
            Last name<span className="text-[#ff0000] text-sm">*</span>
          </label>
          <input
            type="text"
            id="lastName"
            name="lastName"
            value={formData.lastName}
            onChange={(e) => {
              setFormData({ ...formData, lastName: e.target.value });
              setErrors({ ...errors, lastName: "" });
            }}
            className={`focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm text-black text-base border rounded-lg py-1 px-1 ${
              errors.lastName ? "border-[#ff0000]" : ""
            }`}
            placeholder="Last Name"
            style={{ fontSize: "14px" }}
          />
          {/* {errors.lastName && <p className="text-[#ff0000] text-sm">{errors.lastName}</p>} */}
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-3 w-full">
          <label
            htmlFor="email"
            className="block text-inter-regular-14"
            style={{ fontSize: "16px" }}
          >
            Email<span className="text-[#ff0000] text-sm">*</span>
          </label>
          <input
            type="text"
            id="email"
            name="email"
            value={formData.email}
            onChange={(e) => {
              setFormData({ ...formData, email: e.target.value });
              setErrors({ ...errors, email: "" });
            }}
            className={`focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm text-black text-base border rounded-lg py-1 px-1 ${
              errors.email ? "border-[#ff0000]" : ""
            }`}
            placeholder="Email"
            style={{ fontSize: "14px" }}
          />
          {formData.email && (
            <p className="text-[#ff0000] text-sm">{errors.email}</p>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-3">
        <div className="flex flex-col gap-3 w-full">
          <label
            htmlFor="queries"
            className="block text-inter-regular-14"
            style={{ fontSize: "16px" }}
          >
            Queries
          </label>
          <textarea
            id="queries"
            name="queries"
            value={formData.queries}
            onChange={(e) => {
              setFormData({ ...formData, queries: e.target.value });
              setErrors({ ...errors, queries: "" });
            }}
            className="focus:ring-blue-500 focus:border-blue-500 block w-full shadow-sm text-black text-base border border-gray-300 rounded-lg py-2 px-3"
            placeholder="Enter your queries here"
            rows={4}
            style={{ fontSize: "14px" }}
          ></textarea>
        </div>
      </div>
      {isSubmitted && Object.values(errors).some((error) => error) && (
        <p
          className="text-[#ff0000] text-base mb-1"
          style={{ fontSize: "14px" }}
        >
          Look out for mandatory or invalid fields.{" "}
        </p>
      )}
      <div className="flex justify-center">
        <button
          type="submit"
          className="CTABtn lg:block w-fit py-4 px-8 heading5 text-white bg-primary hover:text-black hover:bg-secondary"
          style={{
            boxShadow: "none",
            zIndex: 100,
            textDecoration: "none",
            border: "none",
            outline: "none",
          }}
        >
          <strong>Submit</strong>
        </button>
      </div>
    </form>
  );
};

export default function Careers() {
  const [isJDOpen, setIsJDOpen] = useState(false);
  const [jobIndex, setJobIndex] = useState(0);

  const JDOpenHandler = (index: number) => {
    setJobIndex(index);
    setIsJDOpen(true);
  };

  const JDCloseHandler = () => {
    setIsJDOpen(false);
  };

  return (
    <>
      <section className="">
        <HeroSection {...heroSectionData} />
      </section>
      <section className="bg-[#EAF8FB] py-12 overflow-hidden">
        <div className="container max-w-screen-xl mx-auto">
          <div className="flex flex-col md:flex-row gap-10">
            <div className="w-full md:w-2/5  overflow-hidden">
              <RevealFromBottom className="mb-8">
                <h2 className="text-[32px] sm:text-[32px] lg:text-[46px] font-bold pb-1">
                  Your Life at
                </h2>
                <h2 className="text-[32px] sm:text-[32px] lg:text-[46px] font-bold pb-1">
                  <div className="relative inline-block">
                    <div className="relative z-10 my-2 text-primary">
                      Dreams and Degrees
                    </div>
                    <img
                      src={YourLifeTextHighlight}
                      className="absolute top-1/2 -translate-y-1/2 left-0 z-0"
                    />
                  </div>
                </h2>
              </RevealFromBottom>
              <img
                src="/images/career/life-at-dream.jpg"
                className="hidden md:block lg:w-3/5 lg:object-contain rounded-3xl"
              />
            </div>
            <div className="w-full md:w-3/5 flex flex-col justify-center">
              <div className="flex flex-col md:flex-row gap-6 mb-6">
                {yourLifeAtContentItem(
                  1,
                  "Focused Approach",
                  "Good exposure and growth opportunity to help you grow both personally as well as career wise.",
                  0
                )}
                {yourLifeAtContentItem(
                  2,
                  "Employee Benefits",
                  "Health insurance, Paid time off, Flexible work hours, Training and development opportunities, Career advancement.",
                  450
                )}
              </div>
              <div className="flex flex-col md:flex-row gap-6">
                {yourLifeAtContentItem(
                  3,
                  "Tech Wizards",
                  "Work with experienced members that make challenging projects, fun.",
                  300
                )}
                {yourLifeAtContentItem(
                  4,
                  "Fun at work",
                  "We promote a congenial and collaborative work environment as well as a work-life balance.",
                  150
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="job-list" className="pt-6 pb-12">
        <div className="container max-w-screen-xl">
          <RevealFromBottom>
            <div className="flex items-center justify-center gap-2 pt-8">
              <h2 className="heading2 block">
                Current{" "}
                <div className="relative inline-block">
                  <div className="relative z-10 my-2 text-primary">
                    openings
                  </div>
                  <img
                    src={TextHighlight}
                    className="absolute top-1/2 -translate-y-1/2 left-0 z-0"
                  />
                </div>
              </h2>
            </div>
          </RevealFromBottom>
          <JobBoard jobs={jobData} />
        </div>
      </section>

      <section>
        <div className="bg-[#FCD6A1] py-12">
          <div className="relative container max-w-screen-xl">
            {/* <img src={DotGrid} className="hidden sm:block absolute bottom-0 right-0 h-48 w-48" /> */}
            <RevealFromBottom>
              <div className="max-md:text-epilogue-semibold-28 heading2 pb-1 text-center">
                What our Team says
              </div>
            </RevealFromBottom>
            <img
              src={whatteamsays}
              className="absolute left-0 -translate-y-12 w-[25%]"
            ></img>
            <Slider {...carouselSettings} className="career-testimonial-slider">
              {testimonialItem(
                "/images/career/male-placeholder.png",
                "Barath J",
                "Junior Full-stack Engineer",
                "I joined Dreams and degrees for its collaborative work environment, innovative tech, and exceptional culture. At Dreams and degrees, I have worked with multiple technologies, highlighting the company's commitment to innovation. Last quarter, I had the opportunity to manage a data migration process for a significant architectural transition, ensuring successful implementation. Dreams and degrees values innovation and promotes a dynamic work culture of shared success."
              )}
              {testimonialItem(
                "/images/career/male-placeholder.png",
                "Thiru",
                "Junior Full-stack Engineer",
                "I joined Dreams and degrees for its collaborative work environment, innovative tech, and exceptional culture. At Dreams and degrees, I have worked with multiple technologies, highlighting the company's commitment to innovation. Last quarter, I had the opportunity to manage a data migration process for a significant architectural transition, ensuring successful implementation. Dreams and degrees values innovation and promotes a dynamic work culture of shared success."
              )}
            </Slider>
          </div>
        </div>
      </section>
      <section id="contact-section">
        <div className="container max-w-screen-xl flex flex-col md:flex-row gap-12 py-12">
          <div className="w-full lg:w-1/2 mx-auto">
            <RevealFromBottom className="mb-8">
              <div className="flex items-center justify-center gap-2 pt-8">
                <h2 className="heading2 block">
                  Contact{" "}
                  <div className="relative inline-block">
                    <div className="relative z-10 my-2 text-primary">Us</div>
                    <img
                      src={TextHighlight1}
                      className="absolute top-1/2 -translate-y-1/2 left-0 scale-[130%] z-0"
                    />
                  </div>
                </h2>
              </div>
            </RevealFromBottom>
            <p className="text-inter-regular-16 mb-6">
              Let’s talk about your idea
            </p>
            <MyForm />
          </div>
          <div className="md:hidden lg:w-1/2">
            <img src="/images/career/contact.jpg" className="rounded-3xl"></img>
          </div>
        </div>
      </section>

      <Modal isOpen={isJDOpen} onClose={JDCloseHandler}>
        {isJDOpen && <JDModalContent {...jobData[jobIndex]}></JDModalContent>}
      </Modal>
    </>
  );
}

function yourLifeAtContentItem(
  number: number,
  heading1: string,
  paragraph: string,
  delay: number
) {
  return (
    <Reveal
      keyframes={customAnimationBottom}
      delay={delay}
      className="w-full sm:w-1/2"
    >
      <div className="group lifetext h-full m-6 md:m-0 p-8 border rounded-3xl bg-white hover:text-white transition">
        <span className="block career-number-sm md:career-number group-hover:text-white mb-8">
          {number}
        </span>
        <h3 className="text-inter-bold-20 md:text-inter-24-semibold mb-4 group-hover:text-white">
          {heading1}
        </h3>
        <p className="text-inter-regular-14-sm md:career-para group-hover:text-white">
          {paragraph}
        </p>
      </div>
    </Reveal>
  );
}

function JDModalContent(job: Job) {
  const {
    title,
    minExp,
    maxExp,
    isFullTime,
    location,
    description,
    responsibilities,
    requirements,
    benefits,
  } = job;
  const handleApplyClick = (title: string) => {
    const subject = encodeURIComponent(`Job Application for ${title}`);
    const body = encodeURIComponent(
      `I am interested in applying for the job position ${title}.\n\nPlease attach your resume here.`
    );
    const mailtoLink = `mailto:admin@dreamsanddegrees.com?subject=${subject}&body=${body}`;
    window.location.href = mailtoLink;
  };
  return (
    <div className="p-4">
      <h2 className="text-3xl sm:text-4xl lg:text-5xl mb-2">{title}</h2>
      <p className="text-lg">{`${minExp} - ${maxExp} years | ${
        isFullTime ? "Full Time" : "Part Time"
      } | ${location}`}</p>
      <hr className="my-4" />
      <p className="mb-4">{description}</p>
      <h3 className="text-2xl mb-2">Responsibilities:</h3>
      <ul className="list-disc pl-6 mb-4">
        {responsibilities.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <h3 className="text-2xl mb-2">Requirements:</h3>
      <ul className="list-disc pl-6 mb-4">
        {requirements.map((item, index) => (
          <li key={index}>{item}</li>
        ))}
      </ul>
      <h3 className="text-2xl mb-2">Benefits:</h3>
      <ul className="list-disc pl-6 mb-4">
        {benefits ?? []?.map((item, index) => <li key={index}>{item}</li>)}
      </ul>

      <div className="flex justify-end">
        <button
          onClick={() => handleApplyClick(title)}
          className="CTABtn lg:block w-fit py-4 px-8 heading5 text-white bg-primary hover:text-black hover:bg-secondary"
        >
          Apply
        </button>
      </div>
    </div>
  );
}

function testimonialItem(
  img: string,
  name: string,
  role: string,
  description: string
) {
  return (
    <div className="p-6 mb-16">
      <div className="flex flex-col md:flex-row md:w-[60%] mx-auto items-center border rounded-3xl bg-[#ffffff] p-5 shadow-md">
        <div className="w-full md:w-1/5 mb-4 md:mb-0 mr-3 flex items-center justify-center">
          <img
            className="w-24 h-24 max-w-xs rounded-full"
            src={img}
            alt="Testimonial Image"
          />
        </div>
        <div className="w-full md:w-4/5">
          <div className="flex flex-col md:flex-row items-baseline mb-2">
            <h3 className="text-inter-semibold-24 mr-2 text-center md:text-left">
              {name}
            </h3>
            <p className="text-inter-regular-14 text-[#6f6f6f] text-center md:text-left">
              <span className="text-[#6f6f6f]">({role})</span>
            </p>
          </div>
          <div>
            <p className="mb-4 text-inter-regular-16 truncate-5-lines">
              {description}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
