import React from "react";
interface Project {
  description: string;
  orderIndex: string;
  title: string;
}

interface FundedProjectsProps {
  title: string;
  array: Project[];
}

const FundedProjects: React.FC<FundedProjectsProps> = ({ title, array }) => (
  <section className="latest-updates-section mb-8">
    <h4 className="text-2xl font-semibold bg-blue text-white py-1 px-3">
      {title}
    </h4>

    <div className="space-y-4">
      {array.map((project, index) => (
        <div key={index} className="bg-white p-4">
          <h5 className="text-lg font-semibold">{project.title}</h5>
          <p className="text-base text-black mt-2">{project.description}</p>
        </div>
      ))}
    </div>
  </section>
);

export default FundedProjects;
