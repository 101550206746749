import React from "react";
import PersonalInfoForm from "./PersonalInfoForm";
import CurrentLevelStudy from "./CurrentLevelStudy";
import { useSelector } from "react-redux";
import { RootState } from "../../../../store/store";
import DegreeLookinFor from "./DegreeLookingFor";

import PreferredState from "./PreferToStudy";
import "../Registration.scss";

import { motion, AnimatePresence } from "framer-motion";
import StreamLookingFor from "./StreamLookingFor";
import CourseLookingFor from "./CourseLookingFor";
import StepProgressBar from "./common/Animated/StepProgressBar";
import MovingObject from "./common/Animated/MovingObject";

const MultiStepForm = () => {
  const { step, direction } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const variants = {
    enter: (direction: number) => ({
      x: direction > 0 ? 1000 : -1000,
      opacity: 0,
    }),
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => ({
      x: direction < 0 ? 1000 : -1000,
      opacity: 0,
    }),
  };
  const renderStep = () => {
    switch (step) {
      case 1:
        return <PersonalInfoForm />;
      case 2:
        return <CurrentLevelStudy />;
      case 3:
        return <DegreeLookinFor />;
      case 4:
        return <StreamLookingFor />;
      case 5:
        return <CourseLookingFor />;
      case 6:
        return <PreferredState />;
      default:
        return <PreferredState />;
    }
  };

  const currentStep = step - 1;
  const totalSteps = 5;

  const percentage = (step / 6) * 100;

  return (
    <div
      // className={`flex flex-col justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 max-w-xl`}
      className={`flex flex-col justify-center mx-auto lg:py-0`}
      style={{
        position: "relative",
        // overflow: "hidden",
        // width: "100%",
      }}
    >
      {/* <div
      className={`flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0 max-w-2xl`}
    > */}
      {/* <div className="w-full bg-gray-200 rounded">
            <div
              className="h-2 bg-blue-500 rounded"
              style={{
                width: `${percentage}%`,
                transition: "width 0.3s ease-in-out",
              }}
            />
          </div> */}
      {/* <MovingObject position={(currentStep - 1) * (100 / (totalSteps - 1))} /> */}

      {/* <StepProgressBar currentStep={currentStep} totalSteps={totalSteps} /> */}

      {renderStep()}

      {/* <AnimatePresence initial={false} custom={direction}>
        <motion.div
          key={step}
          custom={direction}
          variants={variants}
          initial="enter"
          animate="center"
          exit="exit"
          transition={{
            x: { type: "spring", stiffness: 300, damping: 30 },
            opacity: { duration: 0.2 },
          }}
          style={{ position: "absolute" }}
        >
          <div className="step-content"> {renderStep()}</div>
        </motion.div>
      </AnimatePresence> */}
    </div>
  );
};

export default MultiStepForm;
