import { useState } from "react";
import { IoMdArrowForward } from "react-icons/io";
import Modal from "../../../../components/Modal";
import { Link } from "react-router-dom";
import QuizWidget, { QuizQuestion } from "../../../../components/courses/QuizWidget";
import LeadGeneration from "../../../../components/LeadGeneration";
import useLeadGeneration from "../../../../hooks/useLeadGeneration";
import { useNavigate, useLocation } from "react-router-dom";

const quizQuestions : QuizQuestion[]= [
  {
    question: "What subjects did you enjoy most in 12th grade?",
    options: [
      { answer: "Mathematics and Physics", suggestion: "Consider Engineering, Computer Science, or Physical Sciences." },
      { answer: "Biology and Chemistry", suggestion: "Look into Medicine, Biotechnology, or Environmental Science." },
      { answer: "Economics and Business Studies", suggestion: "Explore Economics, Commerce, or Business Administration." },
      { answer: "Literature and History", suggestion: "Consider Literature, Arts, Journalism, or History." },
    ],
  },
  {
    question: "How long are you willing to study before starting your career?",
    options: [
      { answer: "3-4 years", suggestion: "Courses like Engineering, Commerce, Computer Science, Arts." },
      { answer: "5-6 years", suggestion: "Courses like Medicine, Law, Architecture." },
      { answer: "Flexible", suggestion: "Combination programs or dual degrees in Business, Law, or research-focused fields." },
    ],
  },
  {
    question: "Are you interested in courses that offer high international mobility?",
    options: [
      { answer: "Yes", suggestion: "Consider Business Administration, Engineering, Medicine, IT." },
      { answer: "Maybe", suggestion: "Explore Environmental Science, Social Work, Education." },
      { answer: "Not a priority", suggestion: "Consider Regional Studies, Public Administration, Indian Law." },
    ],
  },
  {
    question: "What kind of impact do you want your career to have?",
    options: [
      { answer: "Innovation and technology advancement", suggestion: "Consider Computer Science, Engineering, Data Science." },
      { answer: "Health and well-being of others", suggestion: "Consider Medicine, Nursing, Public Health." },
      { answer: "Social and community development", suggestion: "Consider Psychology, Social Work, Public Policy." },
      { answer: "Creativity and cultural enrichment", suggestion: "Consider Fine Arts, Journalism, Media Studies." },
    ],
  },
  {
    question: "What is your interest level in working with data and analytics?",
    options: [
      { answer: "High", suggestion: "Consider Data Science, Economics, Actuarial Science." },
      { answer: "Moderate", suggestion: "Consider Business Analytics, Marketing, Social Sciences." },
      { answer: "Low", suggestion: "Consider Performing Arts, Humanities, Social Work." },
    ],
  },
  {
    question: "What kind of course duration suits your learning style?",
    options: [
      { answer: "Short-term courses", suggestion: "Consider Diplomas in Web Design, Animation, Culinary Arts." },
      { answer: "Bachelor’s degree (3-4 years)", suggestion: "Consider B.A., B.Sc., B.Com. in various fields." },
      { answer: "Professional degree with extended training", suggestion: "Consider Medicine, Architecture, Law." },
    ],
  },
  {
    question: "Are you looking for a course with a structured curriculum or a flexible one with many electives?",
    options: [
      { answer: "Structured", suggestion: "Consider Law, Engineering, Medicine." },
      { answer: "Flexible", suggestion: "Consider Liberal Arts, Humanities, Business." },
    ],
  },
  {
    question: "What type of work-life balance do you envision after your studies?",
    options: [
      { answer: "Intense, high-responsibility roles", suggestion: "Consider Medicine, Law, Aerospace Engineering." },
      { answer: "Balanced, 9-5 jobs", suggestion: "Consider Business, Information Technology, Education." },
      { answer: "Flexible or freelance work", suggestion: "Consider Graphic Design, Freelance Writing, Digital Marketing." },
    ],
  },
  {
    question: "Are you comfortable with high-pressure, fast-paced environments?",
    options: [
      { answer: "Yes", suggestion: "Consider Finance, Media, Healthcare (especially emergency services)." },
      { answer: "Sometimes", suggestion: "Consider Business Management, Marketing, Social Sciences." },
      { answer: "No", suggestion: "Consider Teaching, Research, Environmental Studies." },
    ],
  },
  {
    question: "What’s your interest in courses that involve significant teamwork and collaboration?",
    options: [
      { answer: "High", suggestion: "Consider Business, Engineering, Social Work." },
      { answer: "Moderate", suggestion: "Consider Healthcare, Education, Environmental Science." },
      { answer: "Low", suggestion: "Consider Literature, Mathematics, Research-oriented fields." },
    ],
  },
  {
    question: "What’s your preference for a career that includes travel or fieldwork?",
    options: [
      { answer: "Regular travel", suggestion: "Consider Geology, Environmental Science, Hospitality, International Business." },
      { answer: "Occasional travel", suggestion: "Consider Marketing, Journalism, Consultancy." },
      { answer: "Stationary", suggestion: "Consider Computer Science, Finance, Local Governance." },
    ],
  },
  {
    question: "How important is creativity in your future career?",
    options: [
      { answer: "Very important", suggestion: "Consider Design, Media Studies, Architecture, Performing Arts." },
      { answer: "Somewhat important", suggestion: "Consider Marketing, Business Development, Engineering." },
      { answer: "Not a priority", suggestion: "Consider Accounting, Data Analysis, Technical Writing." },
    ],
  },
  {
    question: "Are you interested in a career that combines your passion for sports with business skills?",
    options: [
      { answer: "Yes", suggestion: "Consider Bachelor’s in Sports Management, combining sports, business, and marketing." },
      { answer: "Somewhat", suggestion: "Consider broader business-focused programs." },
      { answer: "No", suggestion: "Consider other fields outside sports management." },
    ],
  },
  {
    question: "Do you enjoy creative storytelling and the idea of working in television, film, or digital media?",
    options: [
      { answer: "Yes", suggestion: "Consider Bachelor’s in Media Studies or Mass Communication." },
      { answer: "Somewhat", suggestion: "Explore other creative fields with a broader focus." },
      { answer: "No", suggestion: "Consider analytical or structured work fields." },
    ],
  },
  {
    question: "Are you inclined towards fashion, styling, and creating innovative designs?",
    options: [
      { answer: "Absolutely", suggestion: "Consider Bachelor’s in Fashion Design or Fashion Technology." },
      { answer: "Somewhat", suggestion: "Consider broader creative fields." },
      { answer: "Not really", suggestion: "Explore other arts-related fields." },
    ],
  },
  {
    question: "How interested are you in advocating for others?",
    options: [
      { answer: "Very interested", suggestion: "Consider Bachelor of Laws (LL.B.) or BA in Law." },
      { answer: "Interested", suggestion: "Explore other social sciences." },
      { answer: "Not at all", suggestion: "Consider fields outside of law." },
    ],
  },
  {
    question: "Do you have a strong interest in aviation, physics, and the technicalities of flying aircraft?",
    options: [
      { answer: "Yes", suggestion: "Consider Aviation or Pilot Training Program." },
      { answer: "Somewhat", suggestion: "Consider broader technical fields." },
      { answer: "No", suggestion: "Consider grounded professions." },
    ],
  },
  {
    question: "Are you interested in working on nuclear energy, technology, and safety?",
    options: [
      { answer: "Yes", suggestion: "Consider Bachelor’s in Nuclear Engineering or Nuclear Physics." },
      { answer: "Somewhat", suggestion: "Explore broader engineering fields." },
      { answer: "Not really", suggestion: "Consider fields outside nuclear science." },
    ],
  },
  {
    question: "How interested are you in understanding human behavior, emotions, and mental health?",
    options: [
      { answer: "Very interested", suggestion: "Consider Bachelor’s in Psychology." },
      { answer: "Somewhat", suggestion: "Explore social sciences." },
      { answer: "Not at all", suggestion: "Consider other fields." },
    ],
  },
  {
    question: "Do you enjoy studying multiple disciplines and want a broad-based education with flexibility?",
    options: [
      { answer: "Yes", suggestion: "Consider Bachelor’s in Liberal Arts." },
      { answer: "Somewhat", suggestion: "Consider career-focused programs." },
      { answer: "No", suggestion: "Consider specialized fields." },
    ],
  },
  {
    question: "Are you interested in visual arts, creating artwork, and expressing yourself through fine arts?",
    options: [
      { answer: "Absolutely", suggestion: "Consider Bachelor’s in Fine Arts (BFA)." },
      { answer: "Somewhat", suggestion: "Explore broader creative fields." },
      { answer: "Not really", suggestion: "Explore structured careers." },
    ],
  },
  {
    question: "Do you enjoy working with numbers, analyzing financial trends, and managing business accounts?",
    options: [
      { answer: "Yes", suggestion: "Consider Bachelor’s in Commerce (B.Com.) or Bachelor’s in Finance." },
      { answer: "Somewhat", suggestion: "Explore broader business education." },
      { answer: "Not really", suggestion: "Consider creative or analytical fields." },
    ],
  },
];
const BuildingBridges = () => {
  const [isPlaying, setIsPlaying] = useState(false);
  const [mapMyCollegeModalIsOpen, setMapMyCollegeModalIsOpen] = useState(false);
  const [
    contactAdmissionMentorModalIsOpen,
    setContactAdmissionMentorModalIsOpen,
  ] = useState(false);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const handlePlayVideo = () => {
    setIsPlaying(true);
  };
  const { invokeLeadGeneration } = useLeadGeneration();
    const handleLeadGeneration = () => {
      invokeLeadGeneration(
        "college-landing",
        "connect-to-admissionMentor"
      );
    };
    const handleMapMyCollege = () => {
      const user = localStorage.getItem("user");
      if (!user) {
        navigate("/secure/login", { state: { sourcePagePath: pathname } });
      }
    }
  return (
    <>
      <div className="bg-[#FAF8F0] text-black">
        <div className="container py-12 flex flex-col lg:flex-row-reverse gap-12 items-center">
          <div className="w-full lg:w-1/2">
            <h2 className="text-4xl font-bold">
              Building Bridges to Bright Futures
            </h2>
            <h3 className="text-2xl lg:text-xl">
              Guiding Your Child Towards Their Dream College Journey
            </h3>
            <ul className="mt-6 text-grey text-base flex flex-col gap-4">
              {[
                "Meet with a College mentor advisor to discuss your student's needs and goals.",
                "Whether your teen is aiming for the Ivy League, other Private Universities, or small liberal arts colleges",
                "Our mentors are here to guide your family to a dream college and help you navigate scholarship opportunities.",
              ].map((point) => (
                <li>
                  <CircledTickIcon /> {point}
                </li>
              ))}
            </ul>
            <div className="flex flex-wrap gap-4 mt-6 text-base font-bold">
              <button
                className="arrow-button button-styles blue-color items-center"
                onClick={() => {
                  setMapMyCollegeModalIsOpen(true);
                }}
              >
                Map My College in 2 mins
                <IoMdArrowForward className="ms-2 font-bold arrow-icon" />
              </button>
              <button
onClick={handleLeadGeneration}        
        className="arrow-button button-styles green-color flex items-center px-3 py-2 "
              >
                Contact admission mentors
                <IoMdArrowForward className="ms-2 font-bold arrow-icon" />
              </button>
            </div>
          </div>
          <div className="w-full lg:w-1/2">
            {/* height: 0, padding bottom: 56.25% set to maintain specific aspect ratio of height and width */}
            <div className="relative w-full h-0 pb-[56.25%]">
              {isPlaying ? (
                <iframe
                  width="100%"
                  height="100%"
                  src={"https://www.youtube.com/embed/T6oKlQoHrIg?autoplay=1"}
                  title="YouTube Video"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                  className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
                ></iframe>
              ) : (
                <button
                  onClick={handlePlayVideo}
                  className="absolute inset-0 w-full h-full rounded-3xl overflow-hidden left-0 top-0"
                >
                  <img
                    src={
                      "/images/college/landing/building-bridges-video-thumbnail.png"
                    }
                    alt="video thumbnail"
                    className="w-full h-full object-cover rounded-lg"
                  />
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <MapMyCollegePopup
        isOpen={mapMyCollegeModalIsOpen}
        onClose={() => {
          setMapMyCollegeModalIsOpen(false);
        }}
      />
    </>
  );
};

export default BuildingBridges;

const CircledTickIcon = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className="inline-block mr-2"
  >
    <path
      d="M13.6822 4.18881L12.452 5.41166C12.6466 5.98382 12.7523 6.59804 12.7523 7.23751C12.7523 10.3451 10.2544 12.8651 7.17403 12.8651C4.09368 12.8651 1.59577 10.3465 1.59577 7.23751C1.59577 4.12991 4.09368 1.60989 7.17403 1.60989C8.70586 1.60989 10.0931 2.23254 11.1009 3.24082L12.4437 2.32649C11.1329 0.8961 9.2577 0 7.17403 0C3.2124 0 0 3.24082 0 7.23751C0 11.2342 3.2124 14.475 7.17403 14.475C11.1357 14.475 14.3481 11.2342 14.3481 7.23751C14.3481 6.14789 14.109 5.11436 13.6822 4.18881Z"
      fill="#00CC99"
    />
    <path
      d="M7.17371 8.0539C9.22541 5.17769 11.8387 3.24525 14.8009 1.89619C14.9496 1.79663 15.0636 2.00277 14.9594 2.08972C12.2891 4.38255 9.83564 7.11853 7.57404 10.2486C7.34051 10.5066 6.93323 10.4884 6.72333 10.2107L5.32912 8.36101C4.44922 7.25035 3.60685 6.80861 2.80618 7.06945C2.74085 7.09329 2.69498 7.02317 2.75336 6.96428L3.36081 6.24066C4.81758 4.69388 6.47312 7.36675 7.17371 8.0539Z"
      fill="#00CC99"
    />
  </svg>
);

// TODO update modal content
const MapMyCollegePopup = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <QuizWidget questions={quizQuestions} destinationLink="/college-details" />
    </Modal>
  );
};
