import React, { useState } from "react";
import StepNavigationWrapper from "./common/StepNavigationWrapper";
import PlaceholderChipGroup from "./common/PlaceholderChip/PlaceholderChipGroup";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";

import { RootState } from "../../../../store/store";
import {
  nextStep,
  prevStep,
  updateForm,
} from "../../../../store/slices/registrationFormSlice";
import OptionGroup from "./common/OptionsCard/OptionGroup";
import { signupPAFDetail } from "../../../../store/thunks/authThunks";
import axios from "axios";
import config from "../../../../util/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft, faArrowRight } from "@fortawesome/free-solid-svg-icons";
export const PERFERRED_STATES: string[] = [
  "Delhi",
  "Mumbai",
  "Telangana",
  "TamilNadu",
];

const PreferToStudy = () => {
  const dispatch = useDispatch<any>();
  const {
    firstName,
    lastName,
    email,
    mobileNumber,
    currentLevelOfStudy,
    course,
    degree,
    stream,
    city,
    state,
    otp,
  } = useSelector((state: RootState) => state.registrationFormSlice.data);
  const { pafData } = useSelector((state: RootState) => state.auth);
  const { step } = useSelector(
    (state: RootState) => state.registrationFormSlice
  );

  const { register, handleSubmit } = useForm();

  const [selectedCard, setSelectedCard] = useState<any>("");

  const [selectedSingle, setSelectedSingle] = useState<any>(null);

  const onSubmit = (data: any) => {
    // dispatch(updateForm({ preferredStates: selectedCard }));
    dispatch(updateForm({ state: selectedSingle }));
    dispatch(nextStep());
  };

  const handleCardClick = (card: string) => {
    console.log("CourseLookingFor - Message from Child: ", card);
    setSelectedCard(card);
  };

  const handleSingleSelectionChange = (selected: any) => {
    setSelectedSingle(selected);
  };
  let streamName = stream?.streamId?.toString();
  let courseName = course?.courseName;
  const registerUserDetails = async (state: any) => {
    debugger;
    let stateName = state?.stateId;
    let payload = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      // password: "string",
      mobileNumber: mobileNumber,
      currentLevelOfStudy: currentLevelOfStudy,
      lookingForDegree: "degree",
      lookingForStream: streamName,
      lookingForCourse: courseName,
      state: "6",
      city: "5",
      otp: otp,
    };

    try {
      const response = await axios.post(
        config.apiUrl + "/api/auth/user/userExplore",
        payload,
        {
          headers: {
            accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      // setResponseData(response.data.data);
      console.log(response);
    } catch (error) {
      console.error("Error fetching user explore data:", error);
      // setError("An error occurred while fetching data.");
    } finally {
      // setLoading(false);
    }
  };

  const handleNext = () => {
    dispatch(updateForm({ state: selectedCard }));
    dispatch(nextStep());
    // registerUserDetails(selectedCard);
    // dispatch(
    //   signupPAFDetail({
    //     streamId: selectedSingle?.streamId,
    //     courseId: 0,
    //     state: 0,
    //     city: 0,
    //   })
    // )
    //   .unwrap()
    //   .then(() => {
    //     dispatch(nextStep());
    //   })
    //   .catch((err: any) => {
    //     console.error("OTP verification failed:", err);
    //   });
  };

  const handleBoardSelectionChange = (selected: any) => {};
  const handlePrev = () => {
    dispatch(prevStep());
  };

  return (
    <div className="flex flex-col w-auto gap-2">
      <StepNavigationWrapper
        onNext={handleSubmit(onSubmit)}
        showNext={false}
        showBack={true}
        stepName="Which state are You interested in ?"
        stepDescription=""
      >
        <div className="w-full p-4 overflow-auto">
          {/* Old Implementation */}
          {/* <OptionGroup
            mode={"multiple"}
            options={PERFERRED_STATES}
            handleCardSelect={handleCardClick}
            cardSize={"40"}
          /> */}

          {/* <OptionGroup
            options={pafData.states}
            mode="multiple"
            getTitle={(option) =>
              typeof option === "string" ? option : option.stateName
            }
            handleCardSelect={handleCardClick}
            cardSize={"50"}
          /> */}

          <OptionGroup
            options={pafData.states}
            mode="single"
            getTitle={(option) =>
              typeof option === "string" ? option : option.stateName
            }
            onSelectionChange={handleSingleSelectionChange}
            cardSize="auto"
            localStorageKey="states"
          />
          {/* <h3 className="mt-4">Selected Single Options (Full Objects):</h3>
          <pre>{JSON.stringify(selectedSingle, null, 2)}</pre> */}

          {selectedSingle && (
            <div className="mt-4">
              <h4>which City are You Interested in ?</h4>
              <OptionGroup
                options={pafData?.cities} // Assuming you have options for the educational boards
                mode="single"
                getTitle={(option) =>
                  typeof option === "string" ? option : option.cityName
                }
                onSelectionChange={handleBoardSelectionChange} // Define a function for handling board selection
                cardSize="auto"
                localStorageKey="cities"
              />
            </div>
          )}
        </div>
      </StepNavigationWrapper>
      <div className="flex justify-center">
        <div className="flex flex-row items-center gap-2">
          <div className="flex items-center gap-2">
            {/* <button
              className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={handlePrev}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </button>
            <span>Step {step} of 10 </span>
            <button
              className="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-green"
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={handleNext}
            >
              <FontAwesomeIcon icon={faArrowRight} />
            </button> */}


            <button
              className="px-2 py-2 m-3 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={handlePrev}
            >
              Back
            </button>

            <button
              className="px-2 py-2 m-3 text-sm font-semibold duration-200 rounded-full md:px-6 blue-color button-styles"
              style={{ boxShadow: "0 0 8px rgba(0, 0, 0, 0.2)" }}
              onClick={handleNext}
            >
              Next
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PreferToStudy;
