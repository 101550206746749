import React from "react";

const SclorshipObjective = () => {
  return (
    <div className="px-20 py-10 w-full bg-[#FFF9E5] max-md:px-7 max-md:w-full my-6">
      <div className="flex gap-5 max-md:flex-col-reverse">
        <div className="flex flex-col w-[51%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src="/images/scholorshiptest/scholorship-objective.jpg"
            alt="Eligibility Information"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-md:mt-10 max-md:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 ml-5 w-[49%] max-md:ml-0 max-md:w-full my-auto leading-[30px]">
          <div>
            <h2 className="text-xl font-bold">Objective</h2>
            <p>
              To contribute to India's goal of becoming a developed nation by
              2047, College Mentor recognizes the transformative power of
              education.
            </p>
            <p>
              This scholarship aims to reward academic excellence and foster
              growth among Indian students, with a{" "}
              <strong className="text-[#173CBA] md:text-[24px]">
                Total Prize Pool of ₹1 Crore.
              </strong>
            </p>
          </div>
          <div>
            <h2 className="text-xl font-bold">Vision</h2>
            <p>
              We aspire to unite efforts in creating a developed India by
              inspiring youth to cultivate scientific temper and innovative
              thinking.
            </p>
            <p>
              Together, we can unlock India's potential and establish it as a
              Vishwa Guru.
            </p>
          </div>
          <div>
            <h2 className="text-xl font-bold">
              Making India a Developed Nation by 2047
            </h2>
            <p>
              India's youth is its greatest asset. As they shape families and
              society over the next 25-30 years, their involvement in national
              development plans is essential.
            </p>
            <p>Your influence will guide this transformative journey.</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SclorshipObjective;
