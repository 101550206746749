import { ReactNode } from "react";
import CustomSwiper from "../../../../components/Slider/CustomSwiper";
import Arrow from "../../../../images/Arrow.svg";
import { Link } from "react-router-dom";
import useLeadGeneration from "../../../../hooks/useLeadGeneration";

const images = [
  "/images/college/landing/about/about-1.png",
  "/images/college/landing/about/about-2.png",
  "/images/college/landing/about/about-3.png",
  "/images/college/landing/about/about-4.png",
];


const About = () => {
  const { invokeLeadGeneration } = useLeadGeneration();
    const handleLeadGeneration = () => {
      invokeLeadGeneration(
        "college-landing",
        "connect-to-collegeMentor"
      );
    };
    const sliderItems: ReactNode[] = [];

images.forEach((img, index) => {
  if (index == 2) {
    const card = (
      <div className="relative w-full">
        <img src={img} className="w-full" />
        <button onClick= {handleLeadGeneration}    
      className="block absolute right-2 md:right-12 lg:right-20 bottom-1 md:bottom-6 bg-[#ECFFFF] py-0.5 md:py-2 px-2 md:px-4 rounded-full flex items-center gap-2 text-blue text-[10px] md:text-base font-semibold"
        >
          Connect to College Mentor
          <div className="cta flex items-center justify-center">
            <img src={Arrow} className="arrow-icon" />
          </div>
        </button>
      </div>
    );
    sliderItems.push(card);
  } else sliderItems.push(<img src={img} className="w-full" />);
});

  return (
    <div className="my-2 md:my-4">
      <CustomSwiper
        items={sliderItems}
        autoplay={true}
        slidesPerView={1}
        customParams={{ effect: "fade", fadeEffect: { crossFade: true } }}
      />
    </div>
  );
};

export default About;
