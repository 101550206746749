import React, { useState, useEffect } from "react";
import LeadGeneration from "../../../components/LeadGeneration";

export const Banner = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);

  const imagePaths = [
    "/images/applicationToAdmission/college.png",
    "/images/applicationToAdmission/college1.png",
    "/images/applicationToAdmission/college2.png",
    "/images/applicationToAdmission/college3.png",
    "/images/applicationToAdmission/college4.png",
    "/images/applicationToAdmission/college5.png",
    "/images/applicationToAdmission/college2.png",
    "/images/applicationToAdmission/college.png",
    "/images/applicationToAdmission/college3.png",
    "/images/applicationToAdmission/college5.png",
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === imagePaths.length - 1 ? 0 : prevIndex + 1
      );
    }, 3000); // 3 seconds

    return () => clearInterval(interval);
  }, [imagePaths.length]);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  return (
    <div className="mb-6 flex justify-between items-center bg-gradient-to-b  from-[#EDFCFF] to-[#89C9ED] md:h-[620px] overflow-hidden  p-8 pt-16 rounded-lg shadow-lg ">
      <div className="container h-full md:flex-row flex  max-md:flex-col">
        {/* Left Section */}
        <div className="md:w-1/2 md:mt-[100px] max-md:w-full">
          <h3 className="text-4xl font-bold text-[#0C9] mb-4">
            Your Future <span className="text-[#173CBA]">Starts Here</span>
          </h3>
          <p className="text-lg text-black mb-6">
            A leading Career guidance platform for college Application guidance,
            specializing in <strong>UG</strong> and <strong>PG</strong> studies
            both in <strong>India</strong> and <strong>Abroad</strong>.
          </p>
          <p className="text-black mb-6">
            Get connected with everything you need to apply to college, research
            financial aid and scholarships, and get advice from counselors,
            advisors, and mentors.
          </p>
          <button
            onClick={handlePopup}
            className="bg-[#0C9] button-styles arrow-btn flex align-middle text-white text-base font-bold py-3 px-6 rounded-full shadow-lg hover:bg-[#0c9] transition duration-300"
          >
            Apply Now for 2025 Intake{" "}
            <span className="ml-1 my-auto">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="15"
                height="12"
                viewBox="0 0 15 12"
                fill="none"
                className="arrow-icon"
              >
                <path
                  d="M1 5.25C0.585786 5.25 0.25 5.58579 0.25 6C0.25 6.41421 0.585786 6.75 1 6.75L1 5.25ZM14.5303 6.53033C14.8232 6.23744 14.8232 5.76256 14.5303 5.46967L9.75736 0.6967C9.46447 0.403806 8.98959 0.403806 8.6967 0.6967C8.40381 0.989593 8.40381 1.46447 8.6967 1.75736L12.9393 6L8.6967 10.2426C8.40381 10.5355 8.40381 11.0104 8.6967 11.3033C8.98959 11.5962 9.46447 11.5962 9.75736 11.3033L14.5303 6.53033ZM1 6.75L14 6.75L14 5.25L1 5.25L1 6.75Z"
                  fill="white"
                />
              </svg>
            </span>
          </button>
        </div>

        {/* Right Section */}
        <div className="md:w-1/2 relative max-md:w-full max-xl:hidden ">
          <div className="flex justify-center items-center absolute  px-8">
            <div className="relative bg-[#173CBA] rounded-full p-8 h-[690px] w-[690px] flex items-center justify-center">
              {/* Center image */}
              <div className="rounded-full overflow-hidden h-[378px] w-[378px] bg-white">
                <img
                  src={imagePaths[currentImageIndex]}
                  alt="Campus"
                  className="object-cover w-full h-full"
                />
              </div>

              <div className="absolute top-6 text-center w-[448px]">
                <h2 className="text-white text-xl font-normal">
                  College Mentor Students <br />
                  <span className="text-[#0C9]">
                    are Offered Admissions in Top Universities Worldwide
                  </span>
                </h2>
              </div>

              <div className="absolute left-6 w-44">
                <div className="flex flex-col space-y-11">
                  <div className="absolute top-[-40px] right-[-35px] ">
                    <img
                      src="/images/applicationToAdmission/Yale.png"
                      alt="College"
                      className="w-[104px]"
                    />
                  </div>
                  <span className="ml-11">
                    <img
                      src="/images/applicationToAdmission/mellonU.png"
                      alt="College"
                      className="w-20"
                    />
                  </span>
                  <span>
                    <img
                      src="/images/applicationToAdmission/duke.png"
                      alt="College"
                      className="w-20"
                    />
                  </span>
                  <span className="ml-8">
                    <img
                      src="/images/applicationToAdmission/brown.png"
                      alt="College"
                      className="w-20"
                    />
                  </span>
                  <span className="ml-20">
                    <img
                      src="/images/applicationToAdmission/arizona.png"
                      alt="College"
                      className="w-[56px]"
                    />
                  </span>
                </div>
              </div>

              <div className="absolute right-6 flex flex-col space-y-11">
                <span className="ml-[-55px]">
                  <img
                    src="/images/applicationToAdmission/bennet.png"
                    alt="College"
                    className="w-[104px] "
                  />
                </span>
                <span className="ml-[-15px]">
                  <img
                    src="/images/applicationToAdmission/bennet.png"
                    alt="College"
                    className="w-[104px] "
                  />
                </span>
                <span>
                  <img
                    src="/images/applicationToAdmission/bml-munjlal.png"
                    alt="College"
                    className="w-[104px]"
                  />
                </span>
                <span className="ml-[-10px]">
                  <img
                    src="/images/applicationToAdmissionWoxsen.png"
                    alt="SRM"
                    className="w-[91px]"
                  />
                </span>
                <span className="ml-[-50px]">
                  <img
                    src="/images/applicationToAdmission/srm.png"
                    alt="SRM"
                    className="w-[100px]"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
