import React, { useState } from "react";
import bennett_logo from "../../../images/college/details/logo-university.png";

const applicationFormsData = [
  {
    universityName: "Bennett University",
    program: "B.Tech Admissions 2024",
    description:
      "Scholarship Seats Application Deadline - 25th June 2024 | Highest CTC 1.37 Crore Per Annum | Average CTC 7.99 LPA",
    date: "June 29, 2024 19:51 IST",
    logoUrl: "path/to/bennett_logo.png",
  },
  {
    universityName: "Amity University",
    program: "Ranked India's #1",
    description:
      "Not-for-profit Pvt. University for six years - India Today Ranked amongst TOP 3% Universities Globally- QS University Rankings",
    date: "June 29, 2024 19:51 IST",
    logoUrl: "path/to/amity_logo.png",
  },
  {
    universityName: "Lovely Professional University",
    program: "Admissions 2024",
    description:
      "Applications End Date: 30th Jun’24, 100% Placements Record | Highest Package Offered : 3 Cr PA",
    date: "June 29, 2024 19:51 IST",
    logoUrl: "path/to/lpu_logo.png",
  },
  {
    universityName: "Upes University",
    program: "B.Tech Admissions 2024",
    description:
      "Ranked #52 among universities in India by NIRF | Accredited Grade 'A' by NAAC Ranked #243 by QS Asia University Rankings 2023 | University of the year (North India)",
    date: "June 29, 2024 19:51 IST",
    logoUrl: "path/to/upes_logo.png",
  },
  {
    universityName: "Upes University",
    program: "B.Tech Admissions 2024",
    description:
      "Ranked #52 among universities in India by NIRF | Accredited Grade 'A' by NAAC Ranked #243 by QS Asia University Rankings 2023 | University of the year (North India)",
    date: "June 29, 2024 19:51 IST",
    logoUrl: "path/to/upes_logo.png",
  },
];

const ApplicationForms = () => {
  const [showAll, setShowAll] = useState(false);

  const toggleShowAll = () => {
    setShowAll(!showAll);
  };

  return (
    <div className="bg-white shadow-sm">
      <h4 className="w-full bg-blue text-white py-2 mb-4 text-lg text-center font-medium">
        View All Application Forms
      </h4>
      {applicationFormsData
        .slice(0, showAll ? applicationFormsData.length : 4)
        .map((form, index) => (
          <div
            key={index}
            className="border-b border-gray-300 py-4 px-2 flex gap-3"
          >
            <div className="flex">
              <img
                src={form.logoUrl}
                alt={`${form.universityName} Logo`}
                className="w-12 h-12 mr-4"
              />
            </div>
            <div className="flex flex-col gap-1">
              <h3 className="text-sm text-black font-semibold">
                {form.universityName}
              </h3>
              <p className="text-sm font-medium">{form.program}</p>

              <p className="text-sm text-gray-600 mb-2">{form.description}</p>
              <p className="text-xs text-gray-400 mb-2">{form.date}</p>
              <div className="flex items-center justify-end">
                <button className="bg-blue text-white px-4 py-2 rounded-full text-lg">
                  Apply
                </button>
              </div>
            </div>
          </div>
        ))}
      {applicationFormsData.length > 4 && (
        <div className="flex justify-center bg-green">
          <button
            onClick={toggleShowAll}
            className="bg-blue text-white px-4 py-2 rounded-full text-lg"
          >
            {showAll ? "Show Less" : "View All"}
          </button>
        </div>
      )}
    </div>
  );
};

export default ApplicationForms;
