import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { NavigateFunction } from "react-router-dom";
import { RootState } from "../store";

// Thunk for navigating to a step
export const navigateToStep = createAsyncThunk<
  void,
  { step: number; navigate: NavigateFunction },
  { state: RootState }
>(
  "registrationForm/navigateToStep",
  async ({ step, navigate }, { dispatch }) => {
    debugger;
    dispatch(setStep(step)); // Update step in state
    navigate(`/secure/registerv2/step-${step}`); // Navigate to specific URL
  }
);

interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  mobileNumber: string;
  otp: string;
  currentLevelOfStudy: string;
  degree: string;
  stream: any;
  course: any;
  state: any;
  city: string;
  educationalBoard: string | null;
  percentageOption: string | null;
  // preferredStates: [];
}

interface FormState {
  step: number;
  direction: number;
  data: FormData;
}

const initialState: FormState = {
  step: 2,
  direction: 0,
  data: {
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    otp: "",
    currentLevelOfStudy: "",
    degree: "",
    stream: {},
    course: {},
    // preferredStates: [],
    state: {},
    city: "",
    educationalBoard: null,
    percentageOption: null,
  },
};

const registrationFormSlice = createSlice({
  name: "registrationForm",
  initialState,
  reducers: {
    setStep(state, action: PayloadAction<number>) {
      state.step = action.payload;
    },

    nextStep(state) {
      if (state.step < 10) {
        state.step += 1;
        state.direction = 1;
      } else console.log("All steps Reached - show Success / Error Msg!");
    },
    prevStep(state) {
      if (state.step > 0) {
        state.step -= 1;
        state.direction = -1;
      }
    },
    updateForm(state, action: PayloadAction<Partial<FormData>>) {
      state.data = { ...state.data, ...action.payload };
      localStorage.setItem("pafInfo", JSON.stringify(state));
      // Ensure `percentageOption` resets when `educationalBoard` changes
      if (action.payload.educationalBoard) {
        state.data.percentageOption = null;
      }
    },
    resetForm(state) {
      state.step = 1;
      state.data = initialState.data;
    },
  },
});

export const { nextStep, prevStep, updateForm, resetForm, setStep } =
  registrationFormSlice.actions;
export default registrationFormSlice.reducer;
