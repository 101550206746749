import React from "react";

const ScholorshipApply = () => {
  return (
    <div className="px-20 py-10 w-full bg-[#FFF9E5] max-md:px-7 max-md:w-full my-6">
      <div className="flex gap-5 max-md:flex-col-reverse">
        <div className="flex flex-col w-[51%] max-md:ml-0 max-md:w-full">
          <img
            loading="lazy"
            src="/images/scholorshiptest/sc-apply.jpg"
            alt="Eligibility Information"
            className="object-contain grow w-full rounded-xl aspect-[1.45] max-md:mt-10 max-md:max-w-full"
          />
        </div>
        <div className="flex flex-col space-y-6 ml-5 w-[49%] max-md:ml-0 max-md:w-full my-auto leading-[30px]">
          <div>
            <span className="text-black text-base font-semibold">
              How to Apply :
            </span>
            <ul className="list-disc list-inside p-3">
              <li>Six mentorship sessions</li>
              <li>Counseling</li>
            </ul>
            <span className="text-black text-base font-semibold">
              Important Instructions :
            </span>
            <ul className="list-disc list-inside p-3">
              <li>
                The exam can be taken on a laptop, mobile, tablet, or computer
                during the test dates.
              </li>
              <li>
                An AI-powered invigilator will monitor the exam. Any detected
                malpractice will result in immediate termination of the test.
              </li>
              <li>Ensure a stable internet connection to avoid disruptions.</li>
            </ul>
            <span className="text-black text-base font-semibold">
              For Queries :
            </span>
            <ul className="list-disc list-inside p-3">
              <li>Email: scholarship@collegementor.com</li>
              <li>Phone: +91 7997166666</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ScholorshipApply;
