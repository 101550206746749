import React, { useState } from "react";
import LeadGeneration from "../../../components/LeadGeneration";

const ScholorshipBanner = () => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handlePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };
  return (
    <div className="px-20 pt-9 pb-4 max-md:px-5 max-md:max-w-full bg-gradient-to-b from-[#EDFCFF] to-[#89C9ED]">
      <div className="flex gap-5 max-md:flex-col">
        <img
          loading="lazy"
          src="/images/scholorshiptest/scholorship-banner1.svg"
          alt="Left banner img"
          className="object-contain shrink-0 self-stretch my-auto max-w-full aspect-[0.65] w-[296px] max-md:mt-10 max-md:hidden"
        />

        <div className="flex flex-col items-center w-full max-md:mt-10 max-md:max-w-full">
          <img
            loading="lazy"
            src="/images/scholorshiptest/vikasith-bharath.png"
            alt="Scholarship logo"
            className="object-contain max-w-full aspect-[2.69] w-[347px] max-md:w-[203px]"
          />
          <hr className="shrink-0 self-stretch mt-6 h-0.5 border-2 border-white border-solid max-md:max-w-full" />

          <h1 className="mt-3.5 text-5xl font-light text-center md:leading-[55px] text-black max-md:max-w-full max-md:text-[28px] ">
            Get a Chance to Make Your <br /> Dream Happen
          </h1>

          <hr className="shrink-0 self-stretch mt-3.5 h-0.5 border-2 border-white border-solid max-md:max-w-full" />

          <h2 className="mt-4 md:text-3xl font-medium md:leading-10 text-center text-[#008161] max-md:max-w-full px-14 max-md:text-lg">
            Shape Your Dream Destiny with Viksit Bharat Scholarship Test!
          </h2>

          <p className="mt-3 text-2xl font-bold md:leading-9 text-center text-black max-md:text-base">
            <span className="font-light">Empowering </span>
            <span>+2 students with</span>
            <br />
            <span>₹1 Crore in scholarships.</span>
          </p>

          <button
            onClick={handlePopup}
            className="flex gap-0.5 arrow-button items-center hover:bg-[#173CBA] px-4 button-styles map-mentor py-2 mt-5 bg-[#173CBA] min-h-[36px] rounded-[46px]"
          >
            <span className="self-stretch my-auto text-base font-semibold leading-none text-white">
              Apply Now
            </span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="12"
              viewBox="0 0 16 12"
              fill="none"
              className="my-auto arrow-icon"
            >
              <path
                d="M1.16154 5.38269C0.744141 5.38269 0.405772 5.72106 0.405772 6.13846C0.405772 6.55586 0.744141 6.89423 1.16154 6.89423L1.16154 5.38269ZM14.7959 6.67287C15.0911 6.37772 15.0911 5.8992 14.796 5.60405L9.98626 0.794363C9.69112 0.499217 9.21259 0.499217 8.91745 0.794363C8.6223 1.08951 8.6223 1.56804 8.91745 1.86318L13.1927 6.13846L8.91744 10.4137C8.6223 10.7089 8.6223 11.1874 8.91744 11.4826C9.21259 11.7777 9.69112 11.7777 9.98626 11.4826L14.7959 6.67287ZM1.16154 6.89423L14.2615 6.89423L14.2615 5.38269L1.16154 5.38269L1.16154 6.89423Z"
                fill="white"
              />
            </svg>
          </button>
        </div>

        <img
          loading="lazy"
          src="/images/scholorshiptest/scholorship-banner2.svg"
          alt="Right banner img"
          className="object-contain shrink-0 self-stretch my-auto max-w-full aspect-[0.65] w-[296px] max-md:mt-10 max-md:hidden"
        />
        <div className="flex max-md:flex-row md:hidden gap-2">
          <img
            loading="lazy"
            src="/images/scholorshiptest/scholorship-banner2.svg"
            alt="Right banner img"
            className="object-contain shrink-0 self-stretch my-auto max-w-full aspect-[0.65] max-md:w-[50%] max-md:mt-10"
          />
          <img
            loading="lazy"
            src="/images/scholorshiptest/scholorship-banner1.svg"
            alt="Left banner img"
            className="object-contain shrink-0 self-stretch my-auto max-w-full aspect-[0.65] max-md:w-[50%] max-md:mt-10"
          />
        </div>
      </div>
    </div>
  );
};

export default ScholorshipBanner;
