import { faBell } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useNavigate } from "react-router-dom";
import UserAvatar from "../../components/UserAvatar";

const Header = () => {
  const navigate = useNavigate();
  const user = "John";
  const role = "Student";

  const handleLogout = () => {
    localStorage.clear();
    navigate("/");
  };

  return (
    <>
      <div className="flex flex-row w-full bg-white">
        <div className="flex-grow"></div>

        <div className="relative flex flex-row items-center gap-4 py-2 pr-4 user-content md:pr-12">
          {/* <button>
            <FontAwesomeIcon
              icon={faBell}
              className="w-4 h-4 pr-4 text-gray-600"
            />
          </button> */}

          <UserAvatar link="#" logoutUrl={"/secure/login"} />
          {/* <div className="flex items-center justify-center w-12 h-12 ml-2 mr-1 bg-gray-200 rounded-lg shadow-lg">
            <img src="/images/avatar.webp" className="w-8 h-8 text-gray-700" />
          </div>
          <div className="flex flex-col">
            <div className="text-sm font-bold">{user}</div>
            <div className="text-sm">{role}</div>
          </div>
          <button
            className="px-4 py-2 text-base border rounded-full text-blue border-blue"
            onClick={handleLogout}
          >
            Log out
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Header;
